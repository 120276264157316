import { useState } from 'react';

export default function useUserData() {
  const getUserData = () => {
    const userDataString = localStorage.getItem('user_data');
    const userData = userDataString !== null ? JSON.parse(userDataString) : null;
    return userData;
  };

  const [userData, setUserData] = useState(getUserData());

  const saveUserData = (userData: any) => {
    localStorage.setItem('user_data', JSON.stringify(userData));
    setUserData(userData);
  };

  return {
    setUserData: saveUserData,
    userData,
  };
}

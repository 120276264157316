import { Link } from 'react-router-dom';

const Index = () => {
  return (
    <div className="flex justify-center items-center flex-col w-full h-full absolute">
      <div className="text-9xl font-extralight text-transparent bg-clip-text bg-gradient-to-r from-purple-300 to-indigo-600">
        404
      </div>
      <div className="text-lg py-4">OOPS! PAGE NOT FOUND</div>
      <div className="flex justify-center items-center">
        <button
          onClick={() => window.history.back()}
          className="mx-2 cursor-pointer text-sm text-indigo-700 underline"
        >
          Go back
        </button>
        <div>|</div>
        <Link to="/">
          <button className="mx-2 cursor-pointer text-sm text-indigo-700 underline">
            Dashboard
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Index;

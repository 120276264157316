import React, { useState, HTMLInputTypeAttribute } from 'react';
import TextField from '@mui/material/TextField';
import { BiShow, BiHide } from 'react-icons/bi';

interface Props {
  isHorizontal?: boolean;
  title?: string;
  type?: HTMLInputTypeAttribute | undefined;
  isRequired?: boolean;
  isDisabled?: boolean;
  value?: string | ReadonlyArray<string> | number | undefined;
  placeholder?: string | undefined;
  containerClassNames?: string | undefined;
  inputClassNames?: string | undefined;
  onChangeHandler?: (input: any) => void;
  formikError?: any;
  formikTouch?: any;
}

const InputField: React.FC<Props> = ({
  isHorizontal = true,
  title,
  type = 'text',
  isRequired = false,
  isDisabled = false,
  value,
  placeholder,
  inputClassNames = '',
  containerClassNames = '',
  onChangeHandler,
  formikError,
  formikTouch,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <div
      className={`flex ${isHorizontal ? `flex-row` : `flex-col items-start`} 
      ${containerClassNames}`}
    >
      <div className={`font-medium text-base flex-shrink-0 ${isHorizontal ? `mr-5 w-48` : `mb-1`}`}>
        {title}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
      <div className="relative w-full">
        <TextField
          type={type === 'password' && showPassword ? 'text' : type}
          disabled={isDisabled}
          value={value}
          placeholder={placeholder}
          onChange={onChangeHandler}
          className={`h-10 rounded-md font-normal text-base 
          ${isDisabled ? 'bg-gray-300' : 'bg-transparent'}
            ${inputClassNames ? inputClassNames : `w-full`}`}
        />
        {formikError && formikTouch && (
          <div className="text-red-500 text-xs mt-1">{formikError}</div>
        )}
        {type === 'password' ? (
          <button
            className="focus:outline-none absolute top-2 right-2"
            onClick={() => setShowPassword(prevState => !prevState)}
            type="button"
          >
            {showPassword ? (
              <BiShow className="text-gray-400" size={25} />
            ) : (
              <BiHide className="text-gray-400" size={25} />
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default InputField;

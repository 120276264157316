import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';

interface Props {
  title?: string;
  openPopup: boolean;
  setOpenPopup: (input: any) => void;
  children: ReactElement;
  titleClassNames?: string | undefined;
  dialogClassNames?: string | undefined;
  dialogWrapperWidth?: string | undefined;
}

const Popup: React.FC<Props> = ({
  title,
  openPopup,
  children,
  setOpenPopup,
  titleClassNames = '',
  dialogClassNames = '',
  dialogWrapperWidth = '',
}) => {
  const useStyles = makeStyles(() => ({
    dialogWrapper: {
      width: `${dialogWrapperWidth}`,
    },
  }));
  const classes = useStyles();
  return (
    <Dialog open={openPopup} classes={{ paper: classes.dialogWrapper }}>
      <div className={`${dialogClassNames}`}>
        <DialogTitle>
          <div className={`${titleClassNames}`}> {title}</div>
          <IconButton
            aria-label="close"
            onClick={() => setOpenPopup(false)}
            sx={{
              position: 'absolute',
              right: 24,
              top: 8,
              color: theme => theme.palette.grey[500],
              borderRadius: 0,
            }}
          >
            x
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </div>
    </Dialog>
  );
};

export default Popup;

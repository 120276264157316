interface Props {
  data: any;
  containerClassName?: string;
}

const World: React.FC<Props> = ({ data = [], containerClassName }) => {
  return (
    <div className={`${containerClassName} rounded-md`}>
      <div className="font-semibold text-lg text-white mb-4">World</div>
      <div className="overflow-y-auto px-5" style={{ height: '500px' }}>
        {data.map((country: any, i: any) => {
          return (
            <div key={i} className="w-full flex items-center relative my-4">
              <img
                src={`${country.flagURL}`}
                alt={`${country.name} flag`}
                className="object-fill w-14 h-8 mr-10"
              />
              <span className="font-medium text-white">{country.name}</span>
              <span className="absolute right-0 font-medium text-white">
                {country.incidentCount}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default World;

import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import DatePicker from '../../ui/datePicker';
import moment from 'moment';
import FilePicker from '../../ui/filePicker';
import { useHistory } from 'react-router-dom';
import InputField from '../../ui/inputField';
import {
  API_URL,
  sendAuthenticatedGetRequest,
  sendAuthenticatedPostRequest,
} from '../../../services/http';
import Papa from 'papaparse';
import {
  ADD_OBSERVATION_FAILED,
  ADD_OBSERVATION_SUCCESS,
  OVERRIDE_OBSERVATION_FAILED,
  OVERRIDE_OBSERVATION_SUCCESS,
  TOAST_STATUS,
} from '../../../Constants';
import { CircularProgress } from '@mui/material';
import Popup from '../../ui/popup';

const ObservationSchema = Yup.object({
  startDate: Yup.string().required('Time interval is required'),
  report: Yup.mixed().required('Report is required'),
});

const AddObservation: React.FC = () => {
  const history = useHistory();
  const [initialDate, setInitialDate] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(false);
  const [obsStartDates, setObsStartDates] = useState<any>();
  const [openPopup, setOpenPopup] = useState(false);
  const [values, setValues] = useState<any>();

  const endDate: any = moment(initialDate, 'DD/MM/YYYY').add(7, 'days');

  async function getObservationDates() {
    const observationData: any = await sendAuthenticatedGetRequest(`${API_URL}/observation-upload`);

    const dates: any = [];

    if (observationData.data.observationUploads.length > 0) {
      Object.entries(observationData.data.observationUploads).map((item: any) => {
        dates.push(item[1].startDate);
      });
      setObsStartDates(dates);
    }
  }

  useEffect(() => {
    getObservationDates();
  }, []);

  const overrideObs = (override: boolean) => {
    const file: any = values.report;
    Papa.parse(file, {
      header: true,
      complete: function (results: { data: any }) {
        if (results.data) {
          const data = {
            startDate: values.startDate,
            endDate: moment(endDate).format('YYYY-MM-DD'),
            observations: results.data,
            allowOverride: override,
          };

          sendAuthenticatedPostRequest(`${API_URL}/observation-upload`, JSON.stringify(data))
            .then(() => {
              setLoading(false);
              history.push({
                pathname: '/keyObservations',
                state: {
                  toastStatus: TOAST_STATUS,
                  toastMessage: OVERRIDE_OBSERVATION_SUCCESS,
                  severity: 'success',
                },
              });
            })
            .catch(() => {
              setLoading(false);
              history.push({
                pathname: '/keyObservations',
                state: {
                  toastStatus: TOAST_STATUS,
                  toastMessage: OVERRIDE_OBSERVATION_FAILED,
                  severity: 'error',
                },
              });
            });
        }
      },
    });
  };

  const submit = (formikProps: FormikProps<any>) => {
    const { values, errors, submitForm, setSubmitting } = formikProps;
    const errorsExist = Object.keys(errors).length !== 0;
    if (!errorsExist) {
      setValues(values);
    }
    const file: any = values.report;

    const uploadObs = () => {
      submitForm();
      if (!errorsExist) {
        Papa.parse(file, {
          header: true,
          complete: function (results: { data: any }) {
            if (results.data) {
              const data = {
                startDate: values.startDate,
                endDate: moment(endDate).format('YYYY-MM-DD'),
                observations: results.data,
                allowOverride: true,
              };

              sendAuthenticatedPostRequest(`${API_URL}/observation-upload`, JSON.stringify(data))
                .then(() => {
                  setSubmitting(false);
                  setLoading(false);
                  history.push({
                    pathname: '/keyObservations',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: ADD_OBSERVATION_SUCCESS,
                      severity: 'success',
                    },
                  });
                })
                .catch(() => {
                  setSubmitting(false);
                  setLoading(false);
                  history.push({
                    pathname: '/keyObservations',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: ADD_OBSERVATION_FAILED,
                      severity: 'error',
                    },
                  });
                });
            }
          },
        });
      }
    };

    if (obsStartDates) {
      const similarDates: any = obsStartDates.filter((date: any) => date === values.startDate);

      if (similarDates.length > 0) {
        setOpenPopup(true);
      } else {
        uploadObs();
      }
    } else {
      uploadObs();
    }
  };

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Add Key Observation CSV</span>
          <Formik
            initialValues={{
              startDate: '',
              endDate: '',
              report: null,
            }}
            validationSchema={ObservationSchema}
            onSubmit={() => {
              //Do nothing
            }}
          >
            {formikProps => {
              const { values, errors, touched, setFieldValue }: any = formikProps;
              return (
                <Form className="w-full">
                  <div className="border-b-2 border-gray-300 mb-10">
                    <div className="flex">
                      <DatePicker
                        title="Valid time interval"
                        inputFormat="dd/MM/yyyy"
                        isRequired={true}
                        containerClassNames="pr-20 mt-10"
                        value={values ? values.startDate : ''}
                        onChangeHandler={(e: any) => {
                          setFieldValue('startDate', moment(e).format('YYYY-MM-DD'));
                          setInitialDate(e);
                        }}
                        formikError={errors.startDate}
                        formikTouch={touched.startDate}
                      />
                      <InputField
                        title={'To'}
                        containerClassNames={
                          'font-small md:text-left md:mb-0 align-middle text-black-500 mt-10'
                        }
                        inputClassNames={'w-full'}
                        isRequired={true}
                        value={initialDate ? moment(endDate).format('DD/MM/YYYY') : ''}
                        formikError={errors.endDate}
                        formikTouch={touched.endDate}
                      />
                    </div>
                    <FilePicker
                      title="Upload Report"
                      isRequired={true}
                      containerClassNames="mb-10 mt-5"
                      formikError={errors.report}
                      formikTouch={touched.report}
                      fileName={values.report}
                      acceptFileType=".csv"
                      onChangeHandler={(e: any) => {
                        setFieldValue('report', e.target.files[0]);
                      }}
                    />
                  </div>

                  <div className="flex justify-end mt-10">
                    <button
                      className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base"
                      onClick={() =>
                        history.replace({
                          pathname: '/keyObservations',
                        })
                      }
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
                      disabled={loading}
                      onClick={() => {
                        submit(formikProps);
                      }}
                      type="submit"
                    >
                      {loading ? <CircularProgress color="inherit" size={20} /> : 'Add'}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>

          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} dialogWrapperWidth="450px">
            <div>
              <div className="flex justify-center items-center my-7">
                Are you sure you want to override the existing Key observations report for the
                selected date range?
              </div>
              <div className="flex justify-center items-center">
                <button
                  className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base"
                  onClick={() => {
                    overrideObs(false);
                    setOpenPopup(false);
                  }}
                >
                  No
                </button>
                <button
                  className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
                  onClick={() => {
                    overrideObs(true);
                    setOpenPopup(false);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </Layout>
  );
};

export default AddObservation;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TOAST_STATUS } from '../../../Constants';

import Layout from '../Layout';
import DataTable from '../../ui/dataTable/index';
import Toastr from '../../ui/toastr';
import { GridRenderCellParams } from '@mui/x-data-grid';

const Index: React.FC = () => {
  const [urlParams, setUrlParams] = useState<any>({ sortOrder: 'DESC' });
  const [showAlert, setShowAlert] = React.useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const columnData = [
    { field: 'id', headerName: 'ID', flex: 1, filterable: false, sortable: false },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: 'updatedAt',
      headerName: 'Added Date',
      flex: 1,
      filterable: false,
      sortable: false,
      type: 'date',
      renderCell: (params: GridRenderCellParams<Date>) => formatDate(params.value),
    },
  ];

  useEffect(() => {
    let params = {};

    params = { sortOrder: 'DESC' };

    setUrlParams(params);
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const formatDate = (date: any) => {
    return date.substring(0, 10);
  };

  const location: any = useLocation();

  useEffect(() => {
    if (location.state != null) {
      if (location.state.toastStatus == TOAST_STATUS) {
        setShowAlert(true);
        setToastMessage(location.state.toastMessage);
        setSeverity(location.state.severity);
      }
    }
  }, [location]);

  return (
    <Layout>
      <div>
        <div className="w-full">
          <div className="flex justify-between items-center my-8">
            <span className="font-semibold text-gray-700 text-2xl">Key Observations</span>
            <div>
              <Link to="/keyObservations/add">
                <button
                  type="button"
                  className="bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                >
                  Add New Observation
                </button>
              </Link>
            </div>
          </div>

          <DataTable
            url="/observation-upload"
            responseProperty={'observationUploads'}
            rowIdProperty={'id'}
            urlParams={urlParams}
            rowsPerPage={5}
            columnData={columnData}
          />
          <Toastr
            autoHideDuration={6000}
            onClose={handleClose}
            open={showAlert}
            severity={severity && severity}
            message={toastMessage}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Index;

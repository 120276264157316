import { DashboardChartsType, FilterDatesType } from '../../../types/dashboardTypes';
import {
  GET_CHARTS,
  GET_CHARTS_FAILED,
  GET_CHARTS_SUCCESS,
  SET_FILTER_DATES,
} from '../../actions/dashboard';

export interface DashboardInitialState {
  isChartsLoading: boolean;
  chartsError: string | null;
  isFilterUsed: boolean;
  filterDates: FilterDatesType | null;
  charts: DashboardChartsType | null;
}

export const initialState: DashboardInitialState = {
  isChartsLoading: false,
  chartsError: null,
  isFilterUsed: false,
  filterDates: null,
  charts: null,
};

const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CHARTS:
      return { ...state, isChartsLoading: true, chartsError: null };
    case GET_CHARTS_SUCCESS:
      return { ...state, isChartsLoading: false, charts: action.payload };
    case GET_CHARTS_FAILED:
      return { ...state, isChartsLoading: false, chartsError: action.payload };
    case SET_FILTER_DATES:
      return { ...state, isChartsLoading: true, filterDates: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;

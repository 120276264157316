import { SET_INCIDENT_COUNTS_BY_STATUS } from '../../actions/incidents';

export interface IncidentsInitialState {
  incidentCounts: any;
}

export const initialState: IncidentsInitialState = {
  incidentCounts: {},
};

const incidentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_INCIDENT_COUNTS_BY_STATUS:
      return { ...state, incidentCounts: action.payload };
    default:
      return state;
  }
};

export default incidentsReducer;

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { DataGrid, GridColDef, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid';
import { PaletteMode } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import { API_URL, sendAuthenticatedGetRequest } from '../../../services/http';
import { setIncidentCountsByStatus } from '../../../redux/actions/incidents';

interface Props {
  url: string;
  responseProperty?: any;
  rowIdProperty?: any;
  urlParams?: any;
  rowsPerPage: number;
  columnData: GridColDef[];
  hasCheckbox?: boolean;
  onRowClickHandler?: any;
  onRowSelectionHandler?: any;
  theme?: any;
  rowData?: any;
}

const getPalette = (mode: PaletteMode) => ({
  palette: {
    mode,
  },
});

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.5)'
  }`,
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    border: `1px solid ${
      theme.palette.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.5)'
    }`,
  },
  '& *': {
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)',
  },
}));

const DataTable: React.FC<Props> = ({
  url,
  responseProperty,
  rowIdProperty,
  urlParams,
  columnData,
  rowsPerPage,
  hasCheckbox = false,
  onRowClickHandler,
  onRowSelectionHandler,
  theme = 'light',
  rowData = '',
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [pageRows, setPageRows] = useState<GridRowsProp>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const prevSelectionModel = useRef<GridSelectionModel>(selectionModel);
  const themeMode = createTheme(getPalette(theme));

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (url === '') {
        const pageData: any = [];

        for (let i = page * rowsPerPage; i < page * rowsPerPage + rowsPerPage; i++) {
          if (rowData[i]) {
            pageData.push(rowData[i]);
          }
        }
        setPageRows(pageData);
        setRowCount(rowData.length);
      } else {
        urlParams['skip'] = page * rowsPerPage;
        urlParams['take'] = rowsPerPage;

        const rows: any = await sendAuthenticatedGetRequest(
          `${API_URL}${url}`,
          urlParams ? urlParams : null
        );
        dispatch(setIncidentCountsByStatus(rows.data.incidentsByStatus));
        setRowCount(rows.data.totalCount);
        setPageRows(rows.data[responseProperty]);
      }
      setLoading(false);

      setTimeout(() => {
        setSelectionModel(prevSelectionModel.current);
      });
    })();
  }, [page, urlParams]);

  return (
    <div style={{ height: '400px' }}>
      <ThemeProvider theme={themeMode}>
        <StyledDataGrid
          rows={pageRows}
          loading={loading}
          columns={columnData}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
          rowCount={rowCount}
          checkboxSelection={hasCheckbox}
          disableSelectionOnClick
          pagination
          paginationMode="server"
          onRowClick={e => {
            if (onRowClickHandler) {
              onRowClickHandler(e);
            }
          }}
          onPageChange={page => {
            prevSelectionModel.current = selectionModel;
            setPage(page);
          }}
          onStateChange={state => {
            if (hasCheckbox) {
              onRowSelectionHandler(state.selection);
            }
          }}
          onSelectionModelChange={rowIds => {
            setSelectionModel(rowIds);
          }}
          selectionModel={selectionModel}
          getRowId={row => row[rowIdProperty]}
        />
      </ThemeProvider>
    </div>
  );
};

export default DataTable;

import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';

import useUserData from '../../../services/auth/useUserData';
import {
  API_URL,
  sendAuthenticatedGetRequest,
  sendAuthenticatedPostRequest,
} from '../../../services/http';

import Layout from '../Layout';
import InputField from '../../ui/inputField';
import { USER_ROLE_ORGANIZATION } from '../../../Constants';

const PasswordSchema = Yup.object({
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*/=?])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
});

const Index: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showTextField, setShowTextField] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userOrgName, setUserOrgName] = useState('');
  const [userOrgType, setUserOrgType] = useState('');
  const [specifiedCategory, setSpecifiedCategory] = useState('');
  const { userData } = useUserData();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpecifiedField, setShowSpecifiedField] = useState(false);

  const getUserDetail = async () => {
    await sendAuthenticatedGetRequest(`${API_URL}/users/me`)
      .then((userDetailReq: any) => {
        setUserName(userDetailReq.data.name);
        setUserEmail(userDetailReq.data.email);
        setUserOrgName(userDetailReq.data.organizationName);
        setUserOrgType(userDetailReq.data.organizationTypeName);
        setSpecifiedCategory(userDetailReq.data.specifiedCategory);
        if (userDetailReq.data.organizationTypeName == 'Other') {
          setShowSpecifiedField(true);
        }
      })
      .catch(err => {
        setErrorMessage(err.response.data.message);
        setShowError(true);
      });
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <Layout>
      <div className="flex justify-center">
        <form className="mt-10 divide-y-2 w-4/5">
          <div>
            <div className="flex mt-5 flex-row items-center font-medium md:text-left md:mb-5 align-middle text-gray-500">
              <div className="font-normall text-base w-48 mr-5">Name</div>
              <div className="relative font-normal text-black">
                <span> {userName} </span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex mt-5 flex-row items-center font-medium md:text-left md:mb-5 align-middle text-gray-500">
              <div className="font-normall text-base w-48 mr-5">Email</div>
              <div className="relative font-normal text-black">
                <span> {userEmail} </span>
              </div>
            </div>
          </div>
          {userData.role === USER_ROLE_ORGANIZATION ? (
            <>
              <div>
                <div className="flex mt-5 flex-row items-center font-medium md:text-left md:mb-5 align-middle text-gray-500">
                  <div className="font-normall text-base mr-5 w-48">Organization Name</div>
                  <div className="relative font-normal text-black">
                    <span> {userOrgName} </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex mt-5 flex-row items-center font-medium md:text-left md:mb-5 align-middle text-gray-500">
                  <div className="font-normall text-base mr-5 w-48">Organization Type</div>
                  <div className="relative font-normal text-black">
                    <span> {showSpecifiedField ? specifiedCategory : userOrgType} </span>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <Formik
            initialValues={{
              password: '',
            }}
            validationSchema={PasswordSchema}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);
              const data = {
                newPassword: values.password,
                userId: userData.userId,
              };

              await sendAuthenticatedPostRequest(
                `${API_URL}/users/change-password`,
                JSON.stringify(data)
              )
                .then(() => {
                  setLoading(false);
                  resetForm();
                  setShowTextField(false);
                  setShowTitle(true);
                })
                .catch(err => {
                  setLoading(false);
                  setErrorMessage(err.response.data.message);
                  setShowError(true);
                });
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <div className="py-4">
                {showTextField ? (
                  <div>
                    <InputField
                      title={'New Password'}
                      placeholder={'New Password'}
                      type="password"
                      value={values.password}
                      onChangeHandler={handleChange('password')}
                      containerClassNames={'block text-black-500 font-medium w-1/2 mt-5'}
                      formikError={errors.password}
                      formikTouch={touched.password}
                    />
                    {showError && (
                      <div className="w-80 text-red-500 text-xs mt-1">{errorMessage}</div>
                    )}
                  </div>
                ) : null}
                <div className="md:flex md:items-center py-4">
                  <div className="md:w-48">
                    {showTitle ? (
                      <span className="block text-black-500 font-medium md:text-left mb-1">
                        New Password
                      </span>
                    ) : null}
                  </div>
                  <div>
                    <button
                      className="border border-indigo-500 focus:outline-none w-44 h-10 rounded-lg text-indigo-500 text-base ml-5 flex justify-center items-center"
                      type={showTextField ? 'button' : 'submit'}
                      disabled={loading}
                      onClick={() => {
                        showTextField
                          ? handleSubmit()
                          : (setShowTextField(true), setShowTitle(false));
                      }}
                    >
                      {loading ? <CircularProgress color="inherit" size={20} /> : 'Change Password'}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </form>
      </div>
    </Layout>
  );
};

export default Index;

import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  title: string;
  data?: any;
  value?: any;
  isRequired?: boolean;
  isMultiple?: boolean;
  isDisabled?: boolean;
  isVertical?: boolean;
  titleClassNames?: string;
  inputClassNames?: string;
  containerClassNames?: string;
  onChangeHandler?: (input: any) => void;
  formikError?: any;
  formikTouch?: any;
}

const DropDownField: React.FC<Props> = ({
  title,
  data,
  value,
  isRequired = false,
  isMultiple = false,
  isDisabled = false,
  isVertical = false,
  titleClassNames = '',
  inputClassNames = '',
  containerClassNames = '',
  onChangeHandler,
  formikError,
  formikTouch,
}) => {
  return (
    <div
      className={`flex flex-row 
      ${containerClassNames ? containerClassNames : `mt-5`} 
      ${isVertical ? `flex-col items-start` : ``}`}
    >
      <div
        className={`font-medium text-base flex-shrink-0 mr-5 
        ${titleClassNames ? titleClassNames : 'w-48'}`}
      >
        {title}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
      <div className="w-full">
        <Select
          style={{ display: 'inline-grid', overflow: 'hidden' }}
          value={value}
          defaultValue={''}
          multiple={isMultiple}
          disabled={isDisabled}
          onChange={onChangeHandler}
          className={`h-10 rounded-md font-normal text-base text-black
          ${isDisabled ? 'bg-gray-300' : 'bg-transparent'}
            ${inputClassNames ? inputClassNames : `w-full`}`}
        >
          {!isMultiple && <MenuItem value={''}>Select Option</MenuItem>}
          {data?.map((item: any, index: any) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {formikError && formikTouch && (
          <div className="text-red-500 text-xs mt-1">{formikError}</div>
        )}
      </div>
    </div>
  );
};

export default DropDownField;

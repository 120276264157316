import React from 'react';
import Highcharts from 'highcharts';
import highchartsMap from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';

highchartsMap(Highcharts);
const mapData = require('@highcharts/map-collection/countries/lk/lk-all.geo.json');

interface Props {
  data: any;
  containerClassName?: string;
}

const Index: React.FC<Props> = ({ data = [], containerClassName }) => {
  const mapOptions = {
    chart: {
      map: 'countries/lk/lk-all',
      height: '500px',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    colorAxis: {
      min: 0,
      visible: false,
    },
    tooltip: {
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return '<b>' + this.point.properties['woe-name'] + ': ' + this.point.value + '</b>';
      },
      style: {
        color: '#000000',
        fontSize: '13px',
      },
    },
    series: [
      {
        name: 'Incidents',
        mapData: mapData,
        borderColor: 'black',
        nullColor: 'rgba(200, 200, 200, 0.3)',
        showInLegend: false,
        data: data,
        dataLabels: {
          enabled: true,
        },
        states: {
          hover: {
            color: '#fff44f',
          },
        },
      },
    ],
  };

  return (
    <div className={`${containerClassName} rounded-md`}>
      <div className="font-semibold text-lg text-white mb-4">Sri Lanka</div>
      <HighchartsReact
        constructorType={'mapChart'}
        highcharts={Highcharts}
        options={mapOptions}
        allowChartUpdate={true}
      />
    </div>
  );
};

export default Index;

import React, { useState } from 'react';
import Layout from '../Layout';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import FilePicker from '../../ui/filePicker';
import { useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import { CircularProgress } from '@mui/material';
import { API_URL, sendAuthenticatedPutRequest } from '../../../services/http';
import {
  TOAST_STATUS,
  UPDATE_BULK_REPORT_FAILED,
  UPDATE_BULK_REPORT_SUCCESS,
} from '../../../Constants';
import DataTable from '../../ui/dataTable';

const ObservationSchema = Yup.object({
  report: Yup.mixed().required('Report is required'),
});

const AddBulkReport: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [misMatchedList, setmisMatchedList] = useState([]);

  const columnData = [
    {
      field: 'rowNumber',
      headerName: 'Row No.',
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 400,
    },
    {
      field: 'responseTypeName',
      headerName: 'Response',
      width: 200,
    },
    {
      field: 'responseTime',
      headerName: 'Time Taken for Response',
      width: 200,
    },
  ];

  const submit = (formikProps: FormikProps<any>) => {
    const { values, errors, setSubmitting } = formikProps;
    const errorsExist = Object.keys(errors).length !== 0;
    const file: any = values.report;

    const uploadBulk = () => {
      const misMatch: any = [];

      if (!errorsExist) {
        Papa.parse(file, {
          header: true,
          complete: async function (results: { data: any }) {
            if (results.data) {
              const filteredBulkInputData = results.data.filter((bulkData: any, index: any) => {
                return bulkData.link.length > 0;
              });
              const incidents = filteredBulkInputData.map((bulkData: any, index: any) => ({
                rowNumber: index + 1,
                link: bulkData.link,
                isSocialMediaReport: bulkData.reported_to_sm,
                responseTypeName: bulkData.response,
                responseTime: bulkData.time_taken_for_response,
              }));

              await sendAuthenticatedPutRequest(`${API_URL}/incidents/bulk`, {
                incidents,
              })
                .then((response: any) => {
                  if (response.data.incidents.length > 0) {
                    response.data.incidents.map((item: any, index: any) => {
                      if (
                        item.link.isMismatched ||
                        item.responseTypeName.isMismatched ||
                        item.responseTime.isMismatched
                      ) {
                        misMatch.push({ rowNumber: item.rowNumber });
                      }
                      if (item.link.isMismatched) misMatch[index].link = item.link.link;
                      if (item.responseTypeName.isMismatched)
                        misMatch[index].responseTypeName = item.responseTypeName.responseTypeName;
                      if (item.responseTime.isMismatched)
                        misMatch[index].responseTime = item.responseTime.responseTime;
                    });
                    setmisMatchedList(misMatch);
                  } else {
                    setSubmitting(false);
                    setLoading(false);
                    history.push({
                      pathname: '/incidents',
                      state: {
                        toastStatus: TOAST_STATUS,
                        toastMessage: UPDATE_BULK_REPORT_SUCCESS,
                        severity: 'success',
                      },
                    });
                  }
                })
                .catch(() => {
                  setLoading(false);
                  history.push({
                    pathname: '/incidents',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: UPDATE_BULK_REPORT_FAILED,
                      severity: 'error',
                    },
                  });
                });
            }
          },
        });
      }
    };

    uploadBulk();
  };

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Add Bulk Report CSV</span>
          <Formik
            initialValues={{
              report: null,
            }}
            validationSchema={ObservationSchema}
            onSubmit={() => {
              //Do nothing
            }}
          >
            {formikProps => {
              const { values, errors, touched, setFieldValue }: any = formikProps;
              return (
                <Form className="w-full">
                  <div className="border-b-2 border-gray-300 my-10">
                    <FilePicker
                      title="Upload Report"
                      isRequired={true}
                      containerClassNames="mb-10 mt-5"
                      formikError={errors.report}
                      formikTouch={touched.report}
                      fileName={values.report}
                      acceptFileType=".csv"
                      onChangeHandler={(e: any) => {
                        setFieldValue('report', e.target.files[0]);
                      }}
                    />
                  </div>

                  <div>
                    {misMatchedList.length > 0 && (
                      <div>
                        <span className="font-semibold text-red-700 text-sm">
                          Following data has Mismatches!
                        </span>

                        <DataTable
                          url=""
                          rowIdProperty={'rowNumber'}
                          rowsPerPage={5}
                          columnData={columnData}
                          rowData={misMatchedList}
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex justify-end mt-10">
                    <button
                      className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base"
                      onClick={() =>
                        history.replace({
                          pathname: '/incidents',
                        })
                      }
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
                      disabled={loading}
                      onClick={() => {
                        submit(formikProps);
                      }}
                      type="submit"
                    >
                      {loading ? <CircularProgress color="inherit" size={20} /> : 'Report'}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default AddBulkReport;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useToken from '../../services/auth/useToken';
import useUserData from '../../services/auth/useUserData';

const ProtectedRoute = ({ component: Component, roles, ...restOfProps }: any) => {
  const { token } = useToken();
  const { userData } = useUserData();

  const userRoles = roles ? roles : [];
  const isAuthenticated = token !== null ? true : false;
  const userHasRequiredRole = userData && userRoles.includes(userData.role) ? true : false;
  return (
    <Route
      {...restOfProps}
      render={props =>
        isAuthenticated ? (
          userHasRequiredRole ? (
            <Component {...props} />
          ) : (
            <Redirect to="/404" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;

import React, { HTMLInputTypeAttribute } from 'react';

interface Props {
  title?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  value?: string | ReadonlyArray<string> | number | undefined;
  placeholder?: string | undefined;
  containerClassNames?: string | undefined;
  inputClassNames?: string | undefined;
  onChangeHandler?: (input: any) => void;
  formikError?: string | undefined;
  formikTouch?: boolean | undefined;
}

const TextArea: React.FC<Props> = ({
  title,
  isRequired = false,
  isDisabled = false,
  value,
  placeholder,
  inputClassNames = '',
  containerClassNames = '',
  onChangeHandler,
  formikError,
  formikTouch,
}) => {
  return (
    <div
      className={`flex mt-5 flex-row 
      ${containerClassNames}`}
    >
      <div className="font-medium text-base flex-shrink-0 mr-5 w-48">
        {title}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
      <div className="relative w-full">
        <textarea
          disabled={isDisabled}
          className={`appearance-none focus:outline-none h-32 border border-gray-300 rounded-md font-normal text-base text-black pl-2 
            ${isDisabled ? 'bg-gray-300' : 'bg-transparent'}
            ${inputClassNames ? inputClassNames : `w-full`}`}
          value={value}
          placeholder={placeholder}
          onChange={onChangeHandler}
        />
        {formikError && formikTouch && (
          <div className="text-red-500 text-xs mt-1">{formikError}</div>
        )}
      </div>
    </div>
  );
};

export default TextArea;

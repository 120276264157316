import React, { ReactElement, useState } from 'react';
import Layout from '../Layout';
import UserForm from '../../templates/userForm';

export default function AddUserRole(): ReactElement {
  return (
    <Layout>
      <UserForm />
    </Layout>
  );
}

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useState } from 'react';
import { SRILANKA_DISTRICTS_ID } from '../../Constants';
import { API_URL, sendAuthenticatedGetRequest, sendGetRequest } from '../http';

interface Props {
  chartType: string;
  settings?: any;
}

// use to construct timeline for x axis
const timelineHandler = (filterDates: any) => {
  const moment = extendMoment(Moment as any);
  const start = moment(filterDates.startDate, 'YYYY-MM-DD');
  const end = moment(filterDates.endDate, 'YYYY-MM-DD').add(1, 'days');
  const timeline = [];
  while (start.isBefore(end, 'day')) {
    timeline.push(start.format('YYYY-MM-DD'));
    start.add(1, 'days');
  }
  return timeline;
};

// use to fetch target options for scatter plot
const targetOptionHandler = async () => {
  const result: any = [];
  const targetOptions: any = await sendAuthenticatedGetRequest(`${API_URL}/targetOptions`);
  if (targetOptions.data.targetOptions.length > 0) {
    targetOptions.data.targetOptions.forEach((element: any) => {
      if (element.options.length > 0) {
        element.options.forEach((item: any) => {
          result.push(item.name);
        });
      }
    });
  }
  return result;
};

// use to format response data into chart settings
export const chartDataFormatter = async (responseData: any, filterDates: any) => {
  const allCountriesResponse: any = await sendGetRequest('https://restcountries.com/v3.1/all');
  const xAxisTimeline = timelineHandler(filterDates);

  const formattedData: any = {
    incidentSettings: {
      xAxisData: [],
      xAxisName: '',
      yAxisName: 'Entries',
      columnData: [],
      xAxisLabelColor: '#ffffff',
      yAxisLabelColor: '#ffffff',
      zoom: 'outside',
      gridBottom: '15%',
      gridLeft: '2%',
      gridRight: '2%',
    },
    groupByCategorySettings: {
      xAxisData: [],
      xAxisName: '',
      yAxisName: 'No. of Incidents',
      columnData: [],
      xAxisLabelColor: '#ffffff',
      yAxisLabelColor: '#ffffff',
      axisLabelInside: true,
      interval: 0,
      rotate: 90,
      axisLabelFontWeight: 450,
      axisLabelFontSize: 13,
    },
    groupByTargetGroupSettings: {
      xAxisData: [],
      xAxisName: '',
      yAxisName: 'No. of Incidents',
      columnData: [],
      seriesId: 'targetGroup',
      graphic: [
        {
          type: 'text',
          invisible: true,
          silent: true,
          right: 0,
          top: 15,
          style: {
            text: 'Back',
            fontSize: 14,
            borderWidth: 1,
            borderColor: '#6366f1',
            borderRadius: 8,
            padding: 8,
          },
        },
      ],
      xAxisLabelColor: '#ffffff',
      yAxisLabelColor: '#ffffff',
      axisLabelInside: true,
      interval: 0,
      rotate: 90,
      axisLabelFontWeight: 450,
      axisLabelFontSize: 13,
      drillDownData: [],
    },
    groupByPostTypeSettings: {
      xAxisData: [],
      xAxisName: '',
      yAxisName: 'No. of Incidents',
      columnData: [],
      xAxisLabelColor: '#ffffff',
      yAxisLabelColor: '#ffffff',
      axisLabelInside: true,
      interval: 0,
      rotate: 90,
      axisLabelFontWeight: 450,
    },
    groupBySourceSettings: {
      xAxisData: [],
      xAxisName: '',
      yAxisName: 'No. of Incidents',
      columnData: [],
      xAxisLabelColor: '#ffffff',
      yAxisLabelColor: '#ffffff',
      axisLabelInside: true,
      interval: 0,
      rotate: 90,
      axisLabelFontWeight: 450,
    },
    groupByGenderSettings: {
      pieData: [],
      labelPosition: 'outside',
    },
    facebookResponseSettings: {
      pieData: [],
      labelPosition: 'outside',
    },
    tiktokResponseSettings: {
      pieData: [],
      labelPosition: 'outside',
    },
    youtubeResponseSettings: {
      pieData: [],
      labelPosition: 'outside',
    },
    targetGroupsSettings: {
      xAxisName: '',
      yAxisName: 'Total Incidents',
      xAxisData: xAxisTimeline,
      xAxisLabelColor: '#ffffff',
      yAxisLabelColor: '#ffffff',
      scatterData: [],
    },
    locationSettings: {
      lanka: [],
      world: [],
    },
  };

  if (responseData?.incidentsReported) {
    formattedData.incidentSettings.xAxisData = xAxisTimeline;
    // initially assign array with 0s into columnData
    xAxisTimeline.map((x: any) => {
      formattedData.incidentSettings.columnData.push(0);
    });

    // replace 0 with relevant values from API
    xAxisTimeline.map((date: any) => {
      responseData?.incidentsReported.map((item: any) => {
        if (date === item[0]) {
          formattedData.incidentSettings.columnData.splice(xAxisTimeline.indexOf(date), 1, {
            value: item[1],
            itemStyle: { color: '#5FBEE5' },
          });
        }
      });
    });
  }

  if (responseData?.groupByCategory) {
    Object.entries(responseData?.groupByCategory).map((item: any) => {
      formattedData.groupByCategorySettings.xAxisData.push(item[0]);
      formattedData.groupByCategorySettings.columnData.push({
        value: item[1],
        itemStyle: { color: '#5FBEE5' },
      });
    });
  }

  if (responseData?.groupByTargetGroup && responseData?.groupByTargetAndTargetOptions) {
    const targetGroups: any = [];
    const seriesData: any = [];
    const drillDownData: any = [];

    Object.entries(responseData?.groupByTargetGroup).map((item: any) => {
      targetGroups.push(item[0]);
      seriesData.push({ value: item[1], groupId: item[0], itemStyle: { color: '#5FBEE5' } });
    });

    Object.entries(responseData?.groupByTargetAndTargetOptions).map((item: any) => {
      drillDownData.push({
        dataGroupId: item[1].target,
        data: item[1].options,
      });
    });

    formattedData.groupByTargetGroupSettings.xAxisData = targetGroups;
    formattedData.groupByTargetGroupSettings.columnData = seriesData;
    formattedData.groupByTargetGroupSettings.drillDownData = drillDownData;
  }

  if (responseData?.groupByPostType) {
    Object.entries(responseData?.groupByPostType).map((item: any) => {
      formattedData.groupByPostTypeSettings.xAxisData.push(item[0]);
      formattedData.groupByPostTypeSettings.columnData.push({
        value: item[1],
        itemStyle: { color: '#5FBEE5' },
      });
    });
  }

  if (responseData?.groupBySource) {
    Object.entries(responseData?.groupBySource).map((item: any) => {
      formattedData.groupBySourceSettings.xAxisData.push(item[0]);
      formattedData.groupBySourceSettings.columnData.push({
        value: item[1],
        itemStyle: { color: '#5FBEE5' },
      });
    });
  }

  if (responseData?.groupByGender) {
    responseData?.groupByGender.map((item: any) => {
      const dataObj = { value: item[1], name: item[0] };
      formattedData.groupByGenderSettings.pieData.push(dataObj);
    });
  }

  const fbData: any = [];
  const tiktokData: any = [];

  if (responseData?.groupBySMPlatformAndResponseType) {
    responseData?.groupBySMPlatformAndResponseType.map((item: any) => {
      if (item.socialMediaPlatformName === 'Facebook') {
        fbData.push(item);
      }
      if (item.socialMediaPlatformName === 'TikTok') {
        tiktokData.push(item);
      }
    });

    Object.entries(fbData[0].incidentResponseTypeCount).map((item: any) => {
      const dataObj = { value: item[1], name: item[0] };
      formattedData.facebookResponseSettings.pieData.push(dataObj);
    });

    Object.entries(tiktokData[0].incidentResponseTypeCount).map((item: any) => {
      const dataObj = { value: item[1], name: item[0] };
      formattedData.tiktokResponseSettings.pieData.push(dataObj);
    });
  }

  if (responseData?.groupBySMPContentResponseType) {
    Object.entries(responseData?.groupBySMPContentResponseType).map((item: any) => {
      const dataObj = { value: item[1], name: item[0] };
      formattedData.youtubeResponseSettings.pieData.push(dataObj);
    });
  }

  if (responseData?.scatterPlotTargetGroups?.length > 0) {
    const scatterTargetList = await targetOptionHandler();
    const dataSet: any = [];
    const scdata: any = [];
    const tempArray: any = [];

    // initially assign array with nulls into dataSet
    xAxisTimeline.forEach((x: any) => {
      dataSet.push(null);
    });

    // assign default data object for each scatter dataset
    scatterTargetList.forEach((element: any) => {
      const dataObj = {
        name: element,
        type: 'scatter',
        data: [...dataSet],
      };
      formattedData.targetGroupsSettings.scatterData.push(dataObj);
      scdata.push(dataObj);
    });

    // create array containing all the changes
    xAxisTimeline.forEach((element: any) => {
      responseData?.scatterPlotTargetGroups.map((item: any) => {
        if (item.date === element && Object.keys(item.targetOption).length > 0) {
          Object.entries(item.targetOption).map((x: any) => {
            if (x[1] > 0) {
              formattedData.targetGroupsSettings.scatterData.map((category: any) => {
                if (category.name === x[0]) {
                  const tempObj = {
                    categoryName: category.name,
                    timelineIndex: xAxisTimeline.indexOf(item.date),
                    value: x[1],
                  };
                  tempArray.push(tempObj);
                }
              });
            }
          });
        }
      });
    });

    // replace nulls with relevant values
    if (tempArray && scdata.length === scatterTargetList.length) {
      tempArray.forEach((element: any) => {
        const obj = scdata.filter((obj: any) => {
          return obj.name === element.categoryName;
        });
        obj[0].data.splice([element.timelineIndex], 1, element.value);
      });
    }
    const scatterData: any = [];
    scdata
      .filter(
        (data: any) =>
          data.name === 'Muslims' ||
          data.name === 'Tamils' ||
          data.name === 'Sinhalese' ||
          data.name === 'Political Actors' ||
          data.name === 'Christians' ||
          data.name === 'Women' ||
          data.name === 'Minors' ||
          data.name === 'LGBTQI' ||
          data.name === 'Politicians' ||
          data.name === 'Societal' ||
          data.name === 'State Actors' ||
          data.name === 'Activists' ||
          data.name === 'Journalists'
      )
      .map((filterData: any) => {
        scatterData.push(filterData);
      });
    formattedData.targetGroupsSettings.scatterData = [...scatterData];
  }

  if (responseData?.groupByLocation) {
    // Sri Lanka
    const districts = SRILANKA_DISTRICTS_ID;
    const sriLankaData =
      responseData.groupByLocation.length === 0 ? [] : responseData?.groupByLocation?.lk;

    districts.map((district: any) => {
      formattedData.locationSettings.lanka.push([district, 0]);
    });

    districts.map((district: any) => {
      sriLankaData.map((item: any) => {
        if (district === item[0]) {
          formattedData.locationSettings.lanka.push([item[0], item[1]]);
          formattedData.locationSettings.lanka.splice(districts.indexOf(district), 1, item[1]);
        }
      });
    });

    // World
    const countries = allCountriesResponse.data;
    const worldData =
      responseData.groupByLocation.length === 0 ? [] : responseData?.groupByLocation?.world;
    const sortedWorldData: any = [];

    countries.map((country: any) => {
      worldData.map((item: any) => {
        if (country.name.common === item[0]) {
          sortedWorldData.push({
            name: item[0],
            flagURL: country.flags.svg,
            incidentCount: item[1],
          });
        }
      });
    });
    sortedWorldData.sort(
      (a: any, b: any) => parseFloat(b.incidentCount) - parseFloat(a.incidentCount)
    );
    formattedData.locationSettings.world = sortedWorldData;
  }

  return formattedData;
};

// use to customize chart settings
export const chartOptionsHandler = ({ chartType, settings }: Props) => {
  const horizontalChartOptions = {
    height: '90%',
    grid: {
      left: '50%',
      top: '0%',
      bottom: '0%',
    },
    yAxis: [
      {
        type: 'category',
        data: settings?.columnData ? settings.columnData : null,
        axisLabel: {
          margin: 20,
          width: '500',
          overflow: 'break',
          color: settings?.yAxisLabelColor ? settings.yAxisLabelColor : null,
        },
      },
    ],
    xAxis: [
      {
        type: 'value',
        axisLabel: {
          color: settings?.xAxisLabelColor ? settings.xAxisLabelColor : null,
        },
      },
    ],
    series: [
      {
        data: settings?.xAxisData ? settings.xAxisData : null,

        barWidth: '80%',
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)',
        },
        label: {
          show: true,
          position: 'right',
          color: settings?.xAxisLabelColor ? settings.xAxisLabelColor : null,
        },
        itemStyle: {
          normal: {
            barBorderRadius: [0, 5, 5, 0],
          },
        },
      },
    ],
  };

  const columnChartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    dataZoom:
      settings?.zoom === 'outside'
        ? [
            {
              bottom: 10,
            },
          ]
        : settings?.zoom === 'inside'
        ? {
            start: 80,
            type: 'inside',
          }
        : null,
    grid: {
      left: settings?.gridLeft ? settings.gridLeft : '5%',
      right: settings?.gridRight ? settings.gridRight : '2%',
      bottom: settings?.gridBottom ? settings.gridBottom : '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        axisLabel: {
          interval: settings?.interval ? settings.interval : null,
          rotate: settings?.rotate ? settings.rotate : null,
          inside: settings?.axisLabelInside ? settings.axisLabelInside : false,
          color: settings?.xAxisLabelColor ? settings.xAxisLabelColor : null,
          fontWeight: settings?.axisLabelFontWeight ? settings.axisLabelFontWeight : null,
          fontSize: settings?.axisLabelFontSize ? settings.axisLabelFontSize : null,
          margin: 15,
        },
        z: 10,
        data: settings?.xAxisData ? settings.xAxisData : null,
        name: settings?.xAxisName ? settings.xAxisName : null,
        nameLocation: settings?.xAxisNameLocation ? settings.xAxisNameLocation : 'end',
        nameGap: settings?.xNameGap ? settings.xNameGap : -100,
        boundaryGap: true,
        nameTextStyle: {
          fontWeight: 'bolder',
          verticalAlign: 'top',
          lineHeight: 80,
          fontSize: 15,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        name: settings?.yAxisName ? settings.yAxisName : null,
        nameGap: settings?.yNameGap ? settings.yNameGap : 25,
        nameTextStyle: {
          fontWeight: 'bolder',
          fontSize: 15,
          align: 'left',
          verticalAlign: 'bottom',
          color: '#ffffff',
        },
        axisLabel: {
          color: settings?.yAxisLabelColor ? settings.yAxisLabelColor : null,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: 'Entries',
        type: 'bar',
        barWidth: settings?.barWidth ? settings.barWidth : '60%',
        data: settings?.columnData ? settings.columnData : null,
        showBackground: true,
      },
    ],
  };

  const pieChartOptions = {
    tooltip: {
      trigger: 'item',
    },
    color: settings?.pieColors
      ? settings.pieColors
      : ['#005DAC', '#e1c759', '#50be63', '#d69db9', '#DF5E4C', '#04A91E', '#FFACAC'],
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: settings?.pieRadius ? settings.pieRadius : '85%',
        data: settings?.pieData ? settings.pieData : null,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          show: settings?.showLabel ? settings.showLabel : true,
          position: settings?.labelPosition ? settings.labelPosition : 'inside',
          color: settings?.labelPosition === 'outside' ? '#ffffff' : '#000000',
          fontSize: settings?.labelFontSize ? settings.labelFontSize : 10,
          fontWeight: 500,
        },
        labelLine: {
          length: 10,
          length2: 34,
          lineStyle: {
            width: 2,
          },
        },
      },
    ],
  };

  const scatterPlotOptions = {
    grid: {
      left: '3%',
      right: '7%',
      bottom: '17%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      showDelay: 0,
      axisPointer: {
        show: true,
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
      },
    },
    legend: {
      left: 'center',
      bottom: 0,
      textStyle: {
        color: '#ffffff',
      },
    },
    xAxis: [
      {
        scale: true,
        axisLabel: {
          interval: settings?.interval ? settings.interval : null,
          rotate: settings?.rotate ? settings.rotate : null,
          inside: settings?.axisLabelInside ? settings.axisLabelInside : false,
          color: settings?.xAxisLabelColor ? settings.xAxisLabelColor : null,
          fontWeight: settings?.axisLabelFontWeight ? settings.axisLabelFontWeight : null,
          fontSize: settings?.axisLabelFontSize ? settings.axisLabelFontSize : null,
          margin: 15,
        },
        nameLocation: settings?.xAxisNameLocation ? settings.xAxisNameLocation : 'end',
        nameGap: settings?.xNameGap ? settings.xNameGap : -100,
        boundaryGap: true,
        nameTextStyle: {
          fontWeight: 'bolder',
          verticalAlign: 'top',
          lineHeight: 80,
          fontSize: 15,
        },
        data: settings?.xAxisData ? settings.xAxisData : null,
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        scale: true,
        name: settings?.yAxisName ? settings.yAxisName : null,
        nameGap: settings?.yNameGap ? settings.yNameGap : 25,
        nameTextStyle: {
          fontWeight: 'bolder',
          fontSize: 15,
          align: 'left',
          verticalAlign: 'bottom',
          color: '#ffffff',
        },
        axisLabel: {
          color: settings?.yAxisLabelColor ? settings.yAxisLabelColor : null,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: settings?.scatterData ? settings.scatterData : null,
  };

  const stackedColumnChartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: settings?.gridLeft ? settings.gridLeft : '5%',
      right: settings?.gridRight ? settings.gridRight : '5%',
      bottom: settings?.gridBottom ? settings.gridBottom : '10%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: settings?.xAxisData ? settings.xAxisData : null,
        name: settings?.xAxisName ? settings.xAxisName : null,
        nameLocation: settings?.xAxisNameLocation ? settings.xAxisNameLocation : 'end',
        nameGap: settings?.xNameGap ? settings.xNameGap : -100,
        boundaryGap: true,
        nameTextStyle: {
          fontWeight: 'bolder',
          verticalAlign: 'top',
          lineHeight: 80,
          fontSize: 15,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: settings?.yAxisName ? settings.yAxisName : null,
        nameGap: settings?.yNameGap ? settings.yNameGap : 25,
        nameTextStyle: {
          fontWeight: 'bolder',
          fontSize: 15,
          align: 'left',
          verticalAlign: 'bottom',
        },
      },
    ],
    series: settings?.stackSeries ? settings.stackSeries : [],
    dataZoom:
      settings?.zoom === 'outside'
        ? [
            {
              bottom: 0,
            },
          ]
        : settings?.zoom === 'inside'
        ? {
            start: 80,
            type: 'inside',
          }
        : null,
  };

  if (String(chartType) === 'pie') {
    return pieChartOptions;
  } else if (String(chartType) === 'column') {
    return columnChartOptions;
  } else if (String(chartType) === 'scatter') {
    return scatterPlotOptions;
  } else if (String(chartType) === 'stack') {
    return stackedColumnChartOptions;
  } else if (String(chartType) === 'horizontal') {
    return horizontalChartOptions;
  } else return null;
};

import React, { useRef } from 'react';

interface Props {
  title: string;
  acceptFileType: string;
  fileName?: any;
  isRequired?: boolean;
  isDisabled?: boolean;
  containerClassNames?: string;
  onChangeHandler?: any;
  formikError?: any;
  formikTouch?: any;
}

const FilePicker: React.FC<Props> = ({
  title,
  acceptFileType,
  fileName,
  isRequired = false,
  isDisabled = false,
  containerClassNames = '',
  onChangeHandler,
  formikError,
  formikTouch,
}) => {
  const hiddenFileInput = useRef<any>(null);
  return (
    <div className={`flex flex-row ${containerClassNames ? containerClassNames : `mt-5`}`}>
      <div className={`font-medium text-base w-48 flex-shrink-0 mr-5`}>
        {title}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
      <div className="relative w-full">
        <button
          type="button"
          onClick={() => hiddenFileInput.current.click()}
          className={`appearance-none focus:outline-none h-10 rounded-md font-normal text-base leading-none text-center py-3 px-8 ${
            isDisabled
              ? `cursor-not-allowed bg-gray-300 text-black`
              : `cursor-pointer bg-indigo-800 text-white`
          }`}
        >
          {isDisabled ? 'File Chosen' : 'Choose File'}
        </button>
        <span className="font-normal text-sm ml-10 md:ml-6">
          {fileName ? fileName?.name : null}
        </span>
        <input
          disabled={isDisabled}
          className="hidden"
          ref={hiddenFileInput}
          onChange={onChangeHandler}
          type="file"
          accept={acceptFileType}
        />
        {formikError && formikTouch && (
          <div className="text-red-500 text-xs mt-1">{formikError}</div>
        )}
      </div>
    </div>
  );
};

export default FilePicker;

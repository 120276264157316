import React, { ReactElement } from 'react';
import HeaderNavigation from '../templates/navBar/HeaderNavigation';
import SideNavigation from '../templates/sideBar/SideNavigation';
import Footer from '../templates/footer/Footer';

import useUserData from '../../services/auth/useUserData';

interface Props {
  children: ReactElement;
}

const Layout: React.FC<Props> = ({ children }) => {
  const { userData } = useUserData();

  return (
    <>
      <div>
        <div className="relative min-h-screen flex flex-row">
          <SideNavigation userRole={userData.role} />
          <div className="w-5/6 min-h-screen relative">
            <HeaderNavigation userRole={userData.role} userName={userData.name} />
            <div className="children h-full">{children}</div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

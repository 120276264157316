import { API_URL, sendAuthenticatedGetRequest } from '../../../services/http';
import { FilterDatesType } from '../../../types/dashboardTypes';

const PREFIX = '@DASHBOARD_';

export const GET_CHARTS = PREFIX + 'GET_CHARTS';
export const GET_CHARTS_SUCCESS = PREFIX + 'GET_CHARTS_SUCCESS';
export const GET_CHARTS_FAILED = PREFIX + 'GET_CHARTS_FAILED';

export const SET_FILTER_DATES = PREFIX + 'SET_FILTER_DATES';

export const getCharts = (filter?: any) => async (dispatch: any) => {
  try {
    dispatch({ type: GET_CHARTS });

    const response = await sendAuthenticatedGetRequest(`${API_URL}/dashboard`, filter);
    dispatch({ type: GET_CHARTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_CHARTS_FAILED, payload: error });
  }
};

export const setFilterDates = (dates: FilterDatesType) => async (dispatch: any) => {
  dispatch({ type: SET_FILTER_DATES, payload: dates });
};

import React, { useState } from 'react';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface Props {
  userRole?: string;
  userName?: string;
}

const HeaderNavigation: React.FC<Props> = ({ userRole, userName }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const logOut = () => {
    localStorage.clear();
    window.location.pathname = '/login';
  };
  return (
    <nav className="absolute top-0 w-full">
      <div
        style={{ backgroundColor: '#222221' }}
        className="flex justify-end  h-16 md:items-center px-6 py-3"
      >
        <div
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
          className="relative inline-block"
        >
          <div
            className={`flex items-center py-2 ${
              isDropdownOpen ? `text-indigo-500 cursor-pointer` : `text-white`
            }`}
          >
            <Link to={'/profile'}>
              <div className="mr-3 text-sm font-medium">
                {userName} ({userRole})
              </div>
            </Link>
            <FaUserCircle className="text-xl" />
          </div>
          <div
            className={`${
              isDropdownOpen ? `block` : `hidden`
            } absolute w-36 bg-gray-300 text-gray-700 text-sm font-medium`}
          >
            <button onClick={logOut} className="flex items-center justify-between w-full px-3 py-4">
              <div>Logout</div>
              <FaSignOutAlt className="text-lg" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderNavigation;

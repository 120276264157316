import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

import { API_URL, sendAuthenticatedPutRequest } from '../../../services/http';
import Layout from '../Layout';
import InputField from '../../ui/inputField';
import DataTable from '../../ui/dataTable/index';
import Toastr from '../../ui/toastr';
import {
  STATUS_ALL,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  TOAST_STATUS,
} from '../../../Constants';

const columnData = [
  {
    field: 'incidentId',
    headerName: 'ID',
    filterable: false,
    sortable: false,
  },
  {
    field: 'link',
    headerName: 'Link',
    flex: 2,
    filterable: false,
    sortable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 2,
    filterable: false,
    sortable: false,
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'language',
    headerName: 'Language',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'postType',
    headerName: 'Post Type',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'reportedBy',
    headerName: 'Recorded By',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'incidentStatus',
    headerName: 'Status',
    flex: 1,
    filterable: false,
    sortable: false,
  },
];

const Index = () => {
  const history = useHistory();

  const [searchText, setSearchText] = useState<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabType, setTabType] = useState<string>(STATUS_ALL);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [urlParams, setUrlParams] = useState<any>({ sortOrder: 'DESC', sortKey: 'updatedDate' });
  const [showAlert, setShowAlert] = React.useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [statusAllCount, setStatusAllCount] = useState(-1);
  const [statusPendingCount, setStatusPendingCount] = useState(-1);
  const [statusApprovedCount, setStatusApprovedCount] = useState(-1);
  const [statusRejectedCount, setStatusRejectedCount] = useState(-1);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const location: any = useLocation();
  const counts = useSelector((state: RootState) => state.incidents.incidentCounts);

  useEffect(() => {
    if (counts !== undefined) {
      setStatusAllCount(counts.ALL);
      setStatusPendingCount(counts.PENDING);
      setStatusApprovedCount(counts.APPROVED);
      setStatusRejectedCount(counts.REJECTED);
    }
  }, [counts]);

  useEffect(() => {
    if (location.state != null) {
      if (location.state.toastStatus == TOAST_STATUS) {
        setShowAlert(true);
        setToastMessage(location.state.toastMessage);
        setSeverity(location.state.severity);
      }
    }
  }, [location]);

  useEffect(() => {
    let params = {};

    params = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (tabType !== STATUS_ALL) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', status: tabType };
    }
    if (searchText) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', searchKey: searchText.trim() };
      if (tabType !== STATUS_ALL) {
        params = {
          sortOrder: 'DESC',
          sortKey: 'updatedDate',
          status: tabType,
          searchKey: searchText.trim(),
        };
      }
    }
    setUrlParams(params);
  }, [searchText]);

  const tabChangeHandler = (index: number, selectedStatus: string) => {
    let params = {};

    setTabIndex(index);
    setTabType(selectedStatus);
    setSelectedRows([]);

    params = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (selectedStatus !== STATUS_ALL) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', status: selectedStatus };
    }

    if (searchText) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', searchKey: searchText.trim() };
      if (selectedStatus !== STATUS_ALL) {
        params = {
          sortOrder: 'DESC',
          sortKey: 'updatedDate',
          status: selectedStatus,
          searchKey: searchText.trim(),
        };
      }
    }

    setUrlParams(params);
  };

  const reportIncidents = () => {
    const data = { ids: selectedRows };
    sendAuthenticatedPutRequest(`${API_URL}/incidents/bulk/report`, data).then(() => {
      tabChangeHandler(0, STATUS_ALL);
    });
  };

  const rowClickHandler = (row: any) => {
    history.push('/incidents/' + row.id);
  };

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Incidents</span>
          <div className="flex justify-between items-center my-8">
            <InputField
              isHorizontal={false}
              value={searchText}
              onChangeHandler={(email: any) => setSearchText(email.target.value)}
              placeholder="Search..."
              containerClassNames="w-60"
            />
            <div>
              {selectedRows.length > 0 && (
                <button
                  className="bg-red-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                  onClick={() => reportIncidents()}
                  type="button"
                >
                  Report to SM Platform
                </button>
              )}
              {tabIndex == 2 && (
                <Link to="/incident/addBulkReport">
                  <button
                    className="bg-indigo-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                    type="button"
                  >
                    Bulk Report to SM Platform
                  </button>
                </Link>
              )}
              <Link to="/incidents/add">
                <button
                  type="button"
                  className="bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                >
                  Add Incident
                </button>
              </Link>
            </div>
          </div>

          <div className="flex mb-5">
            <button
              onClick={() => tabChangeHandler(0, STATUS_ALL)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 0 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              All {statusAllCount !== -1 && `(` + statusAllCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(1, STATUS_PENDING)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 1 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              Pending {statusPendingCount !== -1 && `(` + statusPendingCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(2, STATUS_APPROVED)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 2 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              Approved {statusApprovedCount !== -1 && `(` + statusApprovedCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(3, STATUS_REJECTED)}
              className={`cursor-pointer focus:outline-none pb-1 ${
                tabIndex === 3 && `border-b-4 border-indigo-500 text-indigo-500`
              }`}
            >
              Rejected {statusRejectedCount !== -1 && `(` + statusRejectedCount + `)`}
            </button>
          </div>
          {/* Replicated for all statuses since MUI DataGrid 
          doesn't support resetting page to zero */}
          {tabType === STATUS_ALL && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
            />
          )}
          {tabType === STATUS_PENDING && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
            />
          )}
          {tabType === STATUS_APPROVED && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              hasCheckbox={true}
              onRowClickHandler={rowClickHandler}
              onRowSelectionHandler={(ids: any) => setSelectedRows(ids)}
            />
          )}
          {tabType === STATUS_REJECTED && (
            <DataTable
              url="/incidents"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
            />
          )}
          <Toastr
            autoHideDuration={6000}
            onClose={handleClose}
            open={showAlert}
            severity={severity && severity}
            message={toastMessage}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Index;

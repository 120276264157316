import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { getCharts } from '../../../redux/actions/dashboard';
import Chart from '../../ui/chart';
import ClickableChart from '../../ui/chart/ClickableChart';
import SriLankaMap from '../../ui/chart/SriLankaMap';
import World from '../../ui/chart/World';
import { chartDataFormatter } from '../../../services/charts';
import { SM_CONTENT_NAME, SM_PLATFORM_NAME } from '../../../Constants';

const DashboardCharts = () => {
  const dispatch = useDispatch();
  const [facebookResponseSettings, setFacebookResponseSettings] = useState();
  const [youtubeResponseSettings, setYoutubeResponseSettings] = useState();
  const [tiktokResponseSettings, setTiktokResponseSettings] = useState();
  const [incidentSettings, setIncidentSettings] = useState();
  const [groupByCategorySettings, setGroupByCategorySettings] = useState();
  const [groupByTargetGroupSettings, setGroupByTargetGroupSettings] = useState();
  const [groupByPostTypeSettings, setGroupByPostTypeSettings] = useState();
  const [groupBySourceSettings, setGroupBySourceSettings] = useState();
  const [groupByGenderSettings, setGroupByGenderSettings] = useState();
  const [targetGroupsSettings, setTargetGroupsSettings] = useState();
  const [sriLankaMapSettings, setSriLankaMapSettings] = useState();
  const [worldSettings, setWorldSettings] = useState();
  const [isChartLoading, setIsChartLoading] = useState(false);
  const componentMounted = useRef(true);

  const charts = useSelector((state: RootState) => state.dashboard.charts);
  const filterDates = useSelector((state: RootState) => state.dashboard.filterDates);

  useEffect(() => {
    if (charts && filterDates) {
      const populateCharts = async () => {
        setIsChartLoading(true);
        const result = await chartDataFormatter(charts, filterDates);

        if (componentMounted.current) {
          setFacebookResponseSettings(result?.facebookResponseSettings);
          setYoutubeResponseSettings(result?.youtubeResponseSettings);
          setTiktokResponseSettings(result?.tiktokResponseSettings);
          setIncidentSettings(result?.incidentSettings);
          setGroupByCategorySettings(result?.groupByCategorySettings);
          setGroupByTargetGroupSettings(result?.groupByTargetGroupSettings);
          setGroupByPostTypeSettings(result?.groupByPostTypeSettings);
          setGroupBySourceSettings(result?.groupBySourceSettings);
          setGroupByGenderSettings(result?.groupByGenderSettings);
          setTargetGroupsSettings(result?.targetGroupsSettings);
          setSriLankaMapSettings(result?.locationSettings.lanka);
          setWorldSettings(result?.locationSettings.world);
          setIsChartLoading(false);
        }
      };
      populateCharts();
    }
  }, [charts, filterDates]);

  useEffect(() => {
    const filters: any = {};
    filters['smPlatformName'] = SM_PLATFORM_NAME;
    filters['smpContentName'] = SM_CONTENT_NAME;
    dispatch(getCharts(filters));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <>
      <Chart
        chartType="column"
        height="350px"
        settings={incidentSettings}
        chartTitle={'Incidents Reported'}
        isLoading={isChartLoading}
      />
      <ClickableChart
        chartData={groupByTargetGroupSettings}
        chartTitle={'Disaggregate by Target Group'}
        isLoading={isChartLoading}
        height="350px"
      />
      <div className="grid grid-cols-2">
        <Chart
          chartType="column"
          height="350px"
          settings={groupByCategorySettings}
          chartTitle={'Disaggregate by Category'}
          isLoading={isChartLoading}
        />
        <Chart
          chartType="column"
          height="350px"
          settings={groupByPostTypeSettings}
          chartTitle={'Disaggregate by Content Type'}
          isLoading={isChartLoading}
        />
      </div>
      <div className="grid grid-cols-2">
        <Chart
          chartType="column"
          height="350px"
          settings={groupBySourceSettings}
          chartTitle={'Disaggregate by Source'}
          isLoading={isChartLoading}
        />
        <Chart
          chartType="pie"
          height="350px"
          settings={groupByGenderSettings}
          chartTitle={'Disaggregate by Gender'}
          isLoading={isChartLoading}
        />
      </div>
      <div className="grid grid-cols-3">
        <Chart
          chartType="pie"
          height="350px"
          settings={facebookResponseSettings}
          chartTitle={'Facebook Response'}
          isLoading={isChartLoading}
        />
        <Chart
          chartType="pie"
          height="350px"
          settings={youtubeResponseSettings}
          chartTitle={'Youtube Response'}
          isLoading={isChartLoading}
        />
        <Chart
          chartType="pie"
          height="350px"
          settings={tiktokResponseSettings}
          chartTitle={'Tiktok Response'}
          isLoading={isChartLoading}
        />
      </div>
      {targetGroupsSettings && (
        <Chart
          chartType="scatter"
          height="550px"
          settings={targetGroupsSettings}
          chartTitle={'Scatter Plot of Target Groups'}
          isLoading={isChartLoading}
        />
      )}
      <div className="flex">
        {sriLankaMapSettings && (
          <SriLankaMap data={sriLankaMapSettings} containerClassName="w-1/2 p-6 pl-4 m-2" />
        )}
        <World data={worldSettings} containerClassName="w-1/2 p-6 pl-4 m-2" />
      </div>
    </>
  );
};

export default DashboardCharts;

import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';

interface Props {
  title?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isVertical?: boolean;
  value?: any;
  containerClassNames?: string;
  inputContainerClassNames?: string;
  inputClassNames?: string;
  titleClassNames?: string;
  onChangeHandler?: any;
  inputFormat?: any;
  formikError?: any;
  formikTouch?: any;
}

const CustomDatePicker: React.FC<Props> = ({
  title,
  isRequired = false,
  isDisabled = false,
  isVertical = false,
  value,
  containerClassNames = '',
  inputContainerClassNames = '',
  inputClassNames = '',
  titleClassNames = '',
  onChangeHandler,
  inputFormat,
  formikError,
  formikTouch,
}) => {
  return (
    <div
      className={`flex flex-row 
      ${containerClassNames ? containerClassNames : `mt-5`} 
      ${isVertical && `flex-col items-start`}`}
    >
      <div
        className={`font-medium text-base flex-shrink-0 mr-5 
        ${titleClassNames ? titleClassNames : 'w-48'}`}
      >
        {title}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
      <div className="w-full">
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            inputFormat={inputFormat}
            value={value}
            onChange={onChangeHandler}
            disabled={isDisabled}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div className={`flex items-center relative ${inputContainerClassNames}`}>
                <input
                  className={`appearance-none focus:outline-none h-10 border border-gray-300 rounded-md font-normal text-base text-black pl-2 
                  ${isDisabled ? 'bg-gray-300' : 'bg-transparent'} ${
                    inputClassNames ? inputClassNames : `w-full`
                  }`}
                  ref={inputRef}
                  {...inputProps}
                />
                <div className="absolute right-3">{InputProps?.endAdornment}</div>
              </div>
            )}
          />
        </LocalizationProvider>
        {formikError && formikTouch && (
          <div className="text-red-500 text-xs mt-1">{formikError}</div>
        )}
      </div>
    </div>
  );
};

export default CustomDatePicker;

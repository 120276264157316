import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { API_URL, AWS_URL, sendAuthenticatedGetRequest } from '../../../services/http';

import Layout from '../Layout';
import IncidentForm from '../../templates/incidentForm';
import useUserData from '../../../services/auth/useUserData';

const Index: React.FC = () => {
  const { userData } = useUserData();
  const { incidentId }: any = useParams();

  const [incidentData, setIncidentData] = useState<any>();

  useEffect(() => {
    (async () => {
      if (incidentId) {
        await sendAuthenticatedGetRequest(`${API_URL}/incidents/${incidentId}`).then((res: any) => {
          const targetCategory = res.data?.targetOptions;
          const formattedTargetCategory: any = {};
          targetCategory.forEach((item: any) => {
            const targetId = item.target.targetId;
            formattedTargetCategory[targetId]
              ? formattedTargetCategory[targetId].push(item.targetOptionId)
              : (formattedTargetCategory[targetId] = [item.targetOptionId]);
          });
          const genderReportCategory = res.data?.genderReportCategories;
          const formattedGenderReportCategory: any = [];
          genderReportCategory.forEach((item: any) => {
            formattedGenderReportCategory.push(item.genderReportCategory.genderReportCategoryId);
          });

          const categoryIntensity = res.data?.incidentIntensityCategories;
          let formattedCategoryIntensity: any = [{ categoryId: '', intensityId: '' }];
          if (categoryIntensity.length !== 0) {
            formattedCategoryIntensity = [];
            categoryIntensity.forEach((item: any) => {
              const object: any = {};
              object['categoryId'] = item.category.categoryId;
              object['intensityId'] = item.intensity.intensityId;
              formattedCategoryIntensity.push(object);
            });
          }

          const data = [
            {
              link: res.data?.link,
              pageLink: res.data?.pageLink,
              description: res.data?.description,
              keyMessage: res.data?.keyMessage?.keyMessageId || '',
              category: formattedCategoryIntensity[0],
              categories: formattedCategoryIntensity.slice(1),
              genderReportCategory: formattedGenderReportCategory,
              targetCategory: [formattedTargetCategory],
              source: res.data?.source?.sourceId,
              screenshot: res.data?.incidentImage ? `${AWS_URL}/${res.data.incidentImage}` : null,
              date: res.data?.date,
              country: res.data?.country?.countryName,
              province: res.data?.location?.regionName,
              district: res.data?.location?.subRegionCode,
              perpetratorGender: res.data?.gender?.genderId,
              language: res.data?.language?.languageId,
              politicalBias: res.data?.politicalBias?.politicalBiasId || '',
              typeOfContent: res.data?.socialMediaPlatformContent?.socialMediaPlatformContentId,
              engagement: res.data?.engagement?.engagementId,
              actualEngagement: res.data?.actualEngagement,
              reportedToSM: res.data?.isSocialMediaReport,
              engagementResponse: res.data?.responseType?.responseTypeId || '',
              timeForResponse: res.data?.responseTime?.responseTimeId || '',
              notes: res.data?.notes,
            },
            {
              incidentStatus: res.data?.incidentStatus,
              reportedByName: res.data?.reportedBy?.name,
              reportedByUserId: res.data?.reportedBy?.userId,
            },
          ];
          setIncidentData(data);
        });
      }
    })();
  }, []);

  return (
    <Layout>
      <IncidentForm userData={userData} incidentData={incidentData} />
    </Layout>
  );
};

export default Index;

import { combineReducers } from 'redux';
import dashboard, { DashboardInitialState } from './dashboard';
import incidents, { IncidentsInitialState } from './incidents';

export interface RootState {
  dashboard: DashboardInitialState;
  incidents: IncidentsInitialState;
}

const rootReducer = combineReducers({ dashboard, incidents });

export default rootReducer;

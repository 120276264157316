import ReactECharts from 'echarts-for-react';
import { chartOptionsHandler } from '../../../services/charts';

interface Props {
  chartType: string;
  settings?: any;
  height: string;
  chartTitle?: string;
  isLoading?: boolean;
}

const Chart = ({ chartType, height, settings, chartTitle, isLoading }: Props) => {
  const chartSettings = chartOptionsHandler({ chartType, settings });

  return (
    <div className="rounded-md p-6 pl-4 m-2">
      {chartTitle && <div className="font-semibold text-lg mb-4 text-white">{chartTitle}</div>}
      {chartSettings ? (
        <ReactECharts showLoading={isLoading} option={chartSettings} style={{ height: height }} />
      ) : (
        <div className="font-semibold">Loading...</div>
      )}
    </div>
  );
};

export default Chart;

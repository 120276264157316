import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

import { API_URL, sendAuthenticatedPostRequest } from '../../../services/http';

import Layout from '../Layout';
import InputField from '../../ui/inputField';
import DropDownField from '../../ui/dropdown';
import FilePicker from '../../ui/filePicker';
import {
  TOAST_STATUS,
  TYPE_OF_REPORT_DROPDOWN,
  ADD_REPORT_SUCCESS,
  ADD_REPORT_FAILED,
} from '../../../Constants';

const ReportSchema = Yup.object({
  name: Yup.string().required('Name is required').max(50, 'Name must be at most 50 characters'),
  typeOfReport: Yup.string().required('Type of report is required'),
  pdf: Yup.mixed()
    .required('Report is required')
    .test('fileSize', 'Max file size is 100MB', value => value && value.size / 1024 / 1024 < 101),
});

const AddReport: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Add Report</span>

          <Formik
            initialValues={{
              name: '',
              typeOfReport: '',
              pdf: null,
            }}
            validationSchema={ReportSchema}
            onSubmit={values => {
              setLoading(true);
              const reportData: any = new FormData();

              reportData.append('name', values.name);
              reportData.append('type', values.typeOfReport);
              reportData.append('file', values.pdf);

              sendAuthenticatedPostRequest(`${API_URL}/reports`, reportData)
                .then(() => {
                  setLoading(false);
                  history.push({
                    pathname: '/reports',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: ADD_REPORT_SUCCESS,
                      severity: 'success',
                    },
                  });
                })
                .catch(() => {
                  setLoading(false);
                  history.push({
                    pathname: '/reports',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: ADD_REPORT_FAILED,
                      severity: 'error',
                    },
                  });
                });
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
              <Form className="w-full">
                <div className="border-b-2 border-gray-300 mb-10">
                  <div className="my-8">
                    <InputField
                      title="Name"
                      isRequired={true}
                      value={values.name}
                      formikError={errors.name}
                      formikTouch={touched.name}
                      onChangeHandler={handleChange('name')}
                      containerClassNames="mt-5"
                    />
                    <DropDownField
                      title="Type of Report"
                      isRequired={true}
                      value={values.typeOfReport}
                      formikError={errors.typeOfReport}
                      formikTouch={touched.typeOfReport}
                      data={TYPE_OF_REPORT_DROPDOWN}
                      onChangeHandler={handleChange('typeOfReport')}
                    />
                    <FilePicker
                      title="Report"
                      isRequired={true}
                      formikError={errors.pdf}
                      formikTouch={touched.pdf}
                      fileName={values.pdf}
                      acceptFileType="application/pdf"
                      onChangeHandler={(e: any) => {
                        setFieldValue('pdf', e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-10">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base"
                    onClick={() =>
                      history.replace({
                        pathname: '/reports',
                      })
                    }
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
                    onClick={() => handleSubmit}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Add'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default AddReport;

import React from 'react';
import { RiDashboard3Fill, RiListCheck2, RiFileUserLine } from 'react-icons/ri';
import { TiDocumentText } from 'react-icons/ti';
import { FaRegEye } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import { USER_ROLE_ADMIN, USER_ROLE_MONITOR } from '../../../Constants';

interface Props {
  userRole?: string;
}

const SideNavigation: React.FC<Props> = ({ userRole }) => {
  return (
    <div style={{ backgroundColor: '#1A1A19' }} className="flex flex-col w-1/6 overflow-hidden">
      <Link to={'/'}>
        <img
          className="object-contain w-36 mx-auto mb-14 mt-14 bg-white p-2"
          src="/logo.png"
          alt="hashtag generation logo"
        />
      </Link>
      <div className="flex flex-col mx-auto cursor-pointer">
        <NavLink
          to="/"
          exact={true}
          activeClassName="selected border-indigo-500 text-indigo-500 "
          className="nav-link border-l-4 border-transparent text-white"
        >
          <div className="flex flex-row items-center py-2">
            <div className="inline-flex items-center justify-center w-12 text-xl">
              <RiDashboard3Fill />
            </div>
            <div className="text-base font-medium">Dashboard</div>
          </div>
        </NavLink>
        <NavLink
          to="/reports"
          activeClassName="selected border-indigo-500 text-indigo-500 "
          className="nav-link border-l-4 border-transparent text-white"
        >
          <div className="flex flex-row items-center py-2">
            <div className="inline-flex items-center justify-center w-12 text-xl">
              <TiDocumentText />
            </div>
            <div className="text-base font-medium">Reports</div>
          </div>
        </NavLink>

        {userRole === USER_ROLE_ADMIN ? (
          <>
            <NavLink
              to="/incidents"
              activeClassName="selected border-indigo-500 text-indigo-500 "
              className="nav-link border-l-4 border-transparent text-white"
            >
              <div className="flex flex-row items-center py-2">
                <div className="inline-flex items-center justify-center w-12 text-xl">
                  <RiListCheck2 />
                </div>
                <div className="text-base font-medium">Incidents</div>
              </div>
            </NavLink>

            <NavLink
              to="/keyObservations"
              activeClassName="selected border-indigo-500 text-indigo-500 "
              className="nav-link border-l-4 border-transparent text-white"
            >
              <div className="flex flex-row items-center py-2">
                <div className="inline-flex items-center justify-center w-12 text-xl">
                  <FaRegEye />
                </div>
                <div className="text-base font-medium">Key Observations</div>
              </div>
            </NavLink>

            <NavLink
              to="/users"
              activeClassName="selected border-indigo-500 text-indigo-500 "
              className="nav-link h-10 border-l-4 border-transparent text-white"
            >
              <div className="flex flex-row items-center py-2">
                <div className="inline-flex items-center justify-center w-12 text-xl">
                  <RiFileUserLine />
                </div>
                <div className="text-base font-medium">Users</div>
              </div>
            </NavLink>
          </>
        ) : null}
        {userRole === USER_ROLE_MONITOR ? (
          <>
            <NavLink
              to="/incidents"
              activeClassName="selected border-indigo-500 text-indigo-500 "
              className="nav-link h-10 border-l-4 border-transparent text-white"
            >
              <div className="flex flex-row items-center py-2">
                <div className="inline-flex items-center justify-center w-12 text-xl">
                  <RiListCheck2 />
                </div>
                <div className="text-base font-medium">Incidents</div>
              </div>
            </NavLink>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SideNavigation;

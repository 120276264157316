import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray, useFormikContext, FormikProps } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { BsPlusCircleFill, BsDashCircleFill } from 'react-icons/bs';

import {
  API_URL,
  sendGetRequest,
  sendAuthenticatedGetRequest,
  sendAuthenticatedPostRequest,
  sendAuthenticatedPutRequest,
} from '../../../services/http';
import { sortArrayAscending } from '../../../services/common';
import { getCountryNames } from '../../../services/country';
import {
  SRILANKA_PROVINCES,
  SRILANKA_DISTRICTS,
  COUNTRY_SRILANKA,
  YES_NO_DROPDOWN,
  USER_ROLE_ADMIN,
  USER_ROLE_MONITOR,
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_NONE,
  ADD_INCIDENT,
  TOAST_STATUS,
  ADD_INCIDENT_SUCCESS,
  ADD_INCIDENT_FAILED,
  UPDATE_INCIDENT_SUCCESS,
  UPDATE_INCIDENT_FAILED,
} from '../../../Constants';

import InputField from '../../ui/inputField';
import DropDownField from '../../ui/dropdown';
import FilePicker from '../../ui/filePicker';
import DatePicker from '../../ui/datePicker';
import TextArea from '../../ui/textArea';

const LoginSchema = Yup.object({
  link: Yup.string()
    .required('Link is required')
    .max(2048, 'Link may not be longer than 2048 characters'),
  pageLink: Yup.string()
    .required('Page / Account link is required')
    .max(2048, 'Page / Account link may not be longer than 2048 characters'),
  description: Yup.string()
    .required('Description is required')
    .max(1500, 'Description may not be longer than 1500 characters'),
  category: Yup.object().shape({
    categoryId: Yup.string().required('Category is required'),
    intensityId: Yup.string().required('Intensity is required'),
  }),
  source: Yup.string().required('Source is required'),
  screenshot: Yup.mixed().required('Screenshot is required'),
  date: Yup.string().required('Date is required'),
  perpetratorGender: Yup.string().required('Perpetrator gender is required'),
  language: Yup.string().required('Language is required'),
  typeOfContent: Yup.string().required('Type of content is required'),
  engagement: Yup.mixed().required('Engagement is required'),
  notes: Yup.string().max(100, 'Note may not be longer than 100 characters'),
});

interface Props {
  userData: any;
  incidentData?: any;
}

const IncidentForm: React.FC<Props> = ({ userData, incidentData }) => {
  const history = useHistory();
  const { incidentId }: any = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [recorderByUserId, setRecorderByUserId] = useState<string>('');
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
  const [reportedToSMDisabled, setReportedToSMDisabled] = useState<boolean>(false);
  const [responseDisabled, setResponseDisabled] = useState<boolean>(false);
  const [timeForResponseDisabled, setTimeForResponseDisabled] = useState<boolean>(false);
  const [notesDisabled, setNotesDisabled] = useState<boolean>(false);
  const [keyMessageList, setKeyMessageList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [intensityList, setIntensityList] = useState<any>([]);
  const [genderReportCategoryList, setGenderReportCategoryList] = useState<any>([]);
  const [targetCategoryList, setTargetCategoryList] = useState<any>([]);
  const [targetCategoryInitialState, setTargetCategoryInitialState] = useState<any>({});
  const [sourceList, setSourceList] = useState<any>([]);
  const [countryList, setCountryList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);
  const [perpetratorGenderList, setPerpetratorGenderList] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  const [politicalBiasList, setPoliticalBiasList] = useState<any>([]);
  const [typeOfContentList, setTypeOfContentList] = useState<any>([]);
  const [engagementList, setEngagementList] = useState<any>([]);
  const [engagementResponseList, setEngagementResponseList] = useState<any>([]);
  const [timeForResponseList, setTimeForResponseList] = useState<any>([]);
  const [hasFormikStateChanged, setHasFormikStateChanged] = useState<boolean>(false);
  const [showSaveAction, setShowSaveAction] = useState<boolean>(false);
  const [showSaveApproveAction, setShowSaveApproveAction] = useState<boolean>(false);
  const [showEditRejectApproveAction, setShowEditRejectApproveAction] = useState<boolean>(false);
  const [showEditCancelApproveAction, setShowEditCancelApproveAction] = useState<boolean>(false);
  const [showAddAction, setShowAddAction] = useState<boolean>(false);
  const [showAddApproveAction, setShowAddApproveAction] = useState<boolean>(false);
  const [showBackAction, setShowBackAction] = useState<boolean>(false);
  const [adminEditFields, setAdminEditFields] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [engagement, setEngagement] = useState<boolean>(false);

  const formikInitialState = {
    link: '',
    pageLink: '',
    description: '',
    keyMessage: '',
    category: { categoryId: '', intensityId: '' },
    categories: [],
    genderReportCategory: [],
    targetCategory: [targetCategoryInitialState],
    source: '',
    screenshot: null,
    date: '',
    country: '',
    province: '',
    district: '',
    perpetratorGender: '',
    language: '',
    politicalBias: '',
    typeOfContent: '',
    engagement: '',
    actualEngagement: '',
    reportedToSM: '',
    engagementResponse: '',
    timeForResponse: '',
    notes: '',
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  useEffect(() => {
    if (incidentData) {
      setRecorderByUserId(incidentData[1].reportedByUserId);
      setHasFormikStateChanged(true);
      if (incidentData[0].screenshot) {
        setImage(incidentData[0].screenshot);
      }
    }
  }, [incidentData]);

  useEffect(() => {
    if (incidentData) {
      if (incidentData[1].incidentStatus === STATUS_PENDING) {
        setFieldDisabled(true);
        setReportedToSMDisabled(true);
        setResponseDisabled(true);
        setTimeForResponseDisabled(true);
        setNotesDisabled(true);

        //If pending and incident reported by logged in monitor
        if (userData.role === USER_ROLE_MONITOR && userData.userId === recorderByUserId) {
          setFieldDisabled(false);
          setReportedToSMDisabled(false);
          setResponseDisabled(false);
          setTimeForResponseDisabled(false);
          setNotesDisabled(false);
        }
      }
      if (incidentData[1].incidentStatus === STATUS_APPROVED) {
        setFieldDisabled(true);
        setReportedToSMDisabled(false);
        setResponseDisabled(false);
        setTimeForResponseDisabled(false);
        setNotesDisabled(false);

        if (userData.role === USER_ROLE_ADMIN && adminEditFields) {
          setFieldDisabled(false);
          setReportedToSMDisabled(false);
          setResponseDisabled(false);
          setTimeForResponseDisabled(false);
          setNotesDisabled(false);
        }
      }
      if (incidentData[1].incidentStatus === STATUS_REJECTED) {
        setFieldDisabled(true);
        setReportedToSMDisabled(true);
        setResponseDisabled(true);
        setTimeForResponseDisabled(true);
        setNotesDisabled(true);
      }
    }
  }, [incidentId, incidentData, userData, recorderByUserId, adminEditFields]);

  useEffect(() => {
    if (incidentData) {
      if (userData.role === USER_ROLE_MONITOR) {
        setShowSaveAction(true);
        setShowEditRejectApproveAction(false);
        setShowAddAction(false);
        setShowAddApproveAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);
        if (
          incidentData[1].incidentStatus === STATUS_PENDING &&
          userData.userId !== recorderByUserId
        ) {
          setShowBackAction(true);
          setShowSaveAction(false);
          setShowEditRejectApproveAction(false);
          setShowAddAction(false);
          setShowAddApproveAction(false);
          setShowSaveApproveAction(false);
        }
      } else if (userData.role === USER_ROLE_ADMIN) {
        if (incidentData[1].incidentStatus === STATUS_REJECTED) {
          setShowEditCancelApproveAction(true);
        } else {
          setShowEditRejectApproveAction(true);
        }
        setShowSaveAction(false);
        setShowAddAction(false);
        setShowAddApproveAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);

        if (adminEditFields) {
          setShowSaveApproveAction(true);
          setShowEditRejectApproveAction(false);
          setShowEditCancelApproveAction(false);
          setShowSaveAction(false);
          setShowAddAction(false);
          setShowAddApproveAction(false);
          setShowBackAction(false);
        }
      }

      if (incidentData[1].incidentStatus === STATUS_REJECTED && userData.role !== USER_ROLE_ADMIN) {
        setShowBackAction(true);
        setShowAddAction(false);
        setShowSaveAction(false);
        setShowEditRejectApproveAction(false);
        setShowAddApproveAction(false);
        setShowSaveApproveAction(false);
      }
    } else {
      if (userData.role === USER_ROLE_MONITOR) {
        setShowAddAction(true);
        setShowSaveAction(false);
        setShowEditRejectApproveAction(false);
        setShowAddApproveAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);
      } else if (userData.role === USER_ROLE_ADMIN) {
        setShowAddApproveAction(true);
        setShowSaveAction(false);
        setShowEditRejectApproveAction(false);
        setShowAddAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);
      }
    }
  }, [incidentId, incidentData, userData, adminEditFields, recorderByUserId]);

  useEffect(() => {
    setFieldDisabled(false);
    setReportedToSMDisabled(false);
    setResponseDisabled(false);
    setTimeForResponseDisabled(false);
    setNotesDisabled(false);
  }, [adminEditFields]);

  const getDropDownData = async () => {
    const keyMessageReq = sendAuthenticatedGetRequest(`${API_URL}/keyMessages`);
    const categoryReq = sendAuthenticatedGetRequest(`${API_URL}/categories`);
    const intensityReq = sendAuthenticatedGetRequest(`${API_URL}/intensities`);
    const genderReportCategoryReq = sendAuthenticatedGetRequest(
      `${API_URL}/genderReportCategories`
    );
    const targetCategoryReq = sendAuthenticatedGetRequest(`${API_URL}/targetOptions`);
    const sourceReq = sendAuthenticatedGetRequest(`${API_URL}/sources`);
    const countriesReq = sendGetRequest('https://restcountries.com/v3.1/all');
    const perpetratorGenderReq = sendAuthenticatedGetRequest(`${API_URL}/genders`);
    const languageReq = sendAuthenticatedGetRequest(`${API_URL}/languages`);
    const politicalBiasReq = sendAuthenticatedGetRequest(`${API_URL}/politicalBias`);
    const typeOfContentReq = sendAuthenticatedGetRequest(`${API_URL}/socialMediaPlatformContents`);
    const engagementReq = sendAuthenticatedGetRequest(`${API_URL}/engagements`);
    const engagementResponseReq = sendAuthenticatedGetRequest(`${API_URL}/responseTypes`);
    const timeForResponseReq = sendAuthenticatedGetRequest(`${API_URL}/responseTimes`);

    await axios
      .all([
        keyMessageReq,
        categoryReq,
        intensityReq,
        genderReportCategoryReq,
        targetCategoryReq,
        sourceReq,
        countriesReq,
        perpetratorGenderReq,
        languageReq,
        politicalBiasReq,
        typeOfContentReq,
        engagementReq,
        engagementResponseReq,
        timeForResponseReq,
      ])
      .then(
        axios.spread(function (
          keyMessageReq: any,
          categoryReq: any,
          intensityReq: any,
          genderReportCategoryReq: any,
          targetCategoryReq: any,
          sourceReq: any,
          countriesReq: any,
          perpetratorGenderReq: any,
          languageReq: any,
          politicalBiasReq: any,
          typeOfContentReq: any,
          engagementReq: any,
          engagementResponseReq: any,
          timeForResponseReq: any
        ) {
          const targetCategoriesResponse = targetCategoryReq.data.targetOptions;

          setKeyMessageList(sortArrayAscending(keyMessageReq.data.keyMessages));
          setCategoryList(sortArrayAscending(categoryReq.data.categories));
          setIntensityList(intensityReq.data.intensities);
          setGenderReportCategoryList(
            sortArrayAscending(genderReportCategoryReq.data.genderReportCategories)
          );
          setTargetCategoryList(targetCategoriesResponse);
          setSourceList(sortArrayAscending(sourceReq.data.sources));
          setCountryList(sortArrayAscending(getCountryNames(countriesReq.data)));
          setPerpetratorGenderList(sortArrayAscending(perpetratorGenderReq.data.genders));
          setLanguageList(sortArrayAscending(languageReq.data.languages));
          setPoliticalBiasList(sortArrayAscending(politicalBiasReq.data.politicalBias));
          setTypeOfContentList(typeOfContentReq.data.socialMediaPlatformContents);
          setEngagementList(engagementReq.data.engagements);
          setEngagementResponseList(engagementResponseReq.data.responseTypes);
          setTimeForResponseList(timeForResponseReq.data.responseTimes);

          const targCatInitState: any = {};
          targetCategoriesResponse.forEach((element: any) => {
            targCatInitState[element.targetId] = '';
          });
          setTargetCategoryInitialState(targCatInitState);
        })
      );
  };

  const onAddCategories = (values: any, setValues: any) => {
    const categories = [...values.categories];
    if (categories.length < 3) {
      categories.push({ categoryId: '', intensityId: '' });
      setValues({ ...values, categories });
    }
  };

  const onRemoveCategories = (i: any, values: any, setValues: any) => {
    const categories = [...values.categories];
    categories.splice(i, 1);
    setValues({ ...values, categories });
  };

  const onChangeProvince = (prov: string) => {
    let province: string = prov;
    if (prov) {
      if (prov === 'North Central') {
        province = 'North_Central';
      }
      if (prov === 'North Western') {
        province = 'North_Western';
      }
      setDistrictList(SRILANKA_DISTRICTS[province]);
    }
  };

  const handleIncidents = (formikProps: FormikProps<any>, status: string = STATUS_NONE) => {
    const { values, errors, submitForm, setSubmitting } = formikProps;
    const errorsExist = Object.keys(errors).length !== 0;

    const targetOptions: any = [];
    const selectedTargetCategories = Object.values(values.targetCategory[0]);
    selectedTargetCategories.forEach(id => {
      targetOptions.push({ targetOptionId: id });
    });

    const genderReportCategories: any = [];
    values.genderReportCategory.forEach((id: any) => {
      genderReportCategories.push({ genderReportCategoryId: id });
    });

    const categoryIntensity: any = [values.category];
    const selectedCategoryIntensities = values.categories;
    selectedCategoryIntensities.forEach((item: any) => {
      categoryIntensity.push(item);
    });

    const formData: any = new FormData();

    formData.append('link', values.link);
    formData.append('pageLink', values.pageLink);
    formData.append('description', values.description);
    formData.append('keyMessageId', values.keyMessage);
    formData.append('categoryIntensities', JSON.stringify(categoryIntensity));
    formData.append('genderReportCategories', JSON.stringify(genderReportCategories));
    formData.append('targetOptions', JSON.stringify(targetOptions));
    formData.append('sourceId', values.source);
    formData.append('date', values.date);
    formData.append('country', values.country);
    formData.append('regionName', values.province);
    formData.append('subRegionCode', values.district);
    formData.append('genderId', values.perpetratorGender);
    formData.append('languageId', values.language);
    formData.append('politicalBiasId', values.politicalBias);
    formData.append('socialMediaPlatformContentId', values.typeOfContent);
    formData.append('engagementId', values.engagement);
    formData.append('actualEngagement', values.actualEngagement);
    formData.append('isSocialMediaReport', values.reportedToSM);
    formData.append('responseTypeId', values.engagementResponse);
    formData.append('responseTimeId', values.timeForResponse);
    formData.append('notes', values.notes);
    if (values.screenshot && typeof values.screenshot !== 'string') {
      formData.append('image', values.screenshot);
    }

    if (status !== STATUS_NONE && status !== ADD_INCIDENT) {
      formData.append('incidentStatus', status);
    }

    submitForm();
    if (!errorsExist) {
      setLoading(true);
      if (status === ADD_INCIDENT) {
        sendAuthenticatedPostRequest(`${API_URL}/incidents`, formData)
          .then(() => {
            setSubmitting(false);
            setLoading(false);
            history.push({
              pathname: '/incidents',
              state: {
                toastStatus: TOAST_STATUS,
                toastMessage: ADD_INCIDENT_SUCCESS,
                severity: 'success',
              },
            });
          })
          .catch(() => {
            setSubmitting(false);
            setLoading(false);
            history.push({
              pathname: '/incidents',
              state: {
                toastStatus: TOAST_STATUS,
                toastMessage: ADD_INCIDENT_FAILED,
                severity: 'error',
              },
            });
          });
      } else {
        sendAuthenticatedPutRequest(`${API_URL}/incidents/${incidentId}`, formData)
          .then(async () => {
            let historyPushed = false;
            if (showSaveApproveAction) {
              formData.append('incidentStatus', STATUS_APPROVED);
              await sendAuthenticatedPutRequest(`${API_URL}/incidents/${incidentId}`, formData)
                .then(() => {
                  historyPushed = true;
                  history.push({
                    pathname: '/incidents',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: UPDATE_INCIDENT_SUCCESS,
                      severity: 'success',
                    },
                  });
                })
                .catch(() => {
                  historyPushed = true;
                  history.push({
                    pathname: '/incidents',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: UPDATE_INCIDENT_FAILED,
                      severity: 'error',
                    },
                  });
                });
            }
            setSubmitting(false);
            setLoading(false);
            if (!historyPushed) {
              history.push({
                pathname: '/incidents',
                state: {
                  toastStatus: TOAST_STATUS,
                  toastMessage: UPDATE_INCIDENT_SUCCESS,
                  severity: 'success',
                },
              });
            }
          })
          .catch(() => {
            setSubmitting(false);
            setLoading(false);
            history.push({
              pathname: '/incidents',
              state: {
                toastStatus: TOAST_STATUS,
                toastMessage: UPDATE_INCIDENT_FAILED,
                severity: 'error',
              },
            });
          });
      }
    }
  };

  const FormikLogger = () => {
    const formik: any = useFormikContext();

    useEffect(() => {
      onChangeProvince(formik.values.province);
    }, [formik.values.province]);

    useEffect(() => {
      if (formik.values.country !== COUNTRY_SRILANKA) {
        formik.setFieldValue('province', '');
        formik.setFieldValue('district', '');
      }
    }, [formik.values.country]);

    useEffect(() => {
      if (engagement) {
        const params =
          formik.values.actualEngagement.length !== 0
            ? { actualEngagement: formik.values.actualEngagement }
            : null;

        sendAuthenticatedGetRequest(`${API_URL}/engagements`, params).then((res: any) => {
          formik.setFieldValue(
            'engagement',
            params ? res.data?.engagements[0].id : incidentData ? incidentData[0].engagement : null
          );
        });
      }
    }, [formik.values.actualEngagement]);

    return null;
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={hasFormikStateChanged ? incidentData[0] : formikInitialState}
        validationSchema={LoginSchema}
        onSubmit={() => {
          //Do nothing
        }}
      >
        {formikProps => {
          const { values, errors, touched, handleChange, setFieldValue, setValues }: any =
            formikProps;
          return (
            <Form className="w-full">
              <FormikLogger />
              {/* Incident Details */}
              <div className="border-b-2 border-gray-300 mb-10">
                <span className="font-semibold text-gray-700 text-2xl">Incident Details</span>
                <div className="my-8">
                  <InputField
                    title="Link"
                    isDisabled={fieldDisabled}
                    value={values ? values.link : ''}
                    onChangeHandler={handleChange('link')}
                    isRequired={true}
                    formikError={errors.link}
                    formikTouch={touched.link}
                    containerClassNames="mt-5"
                  />
                  <InputField
                    title="Page/Account Link"
                    isDisabled={fieldDisabled}
                    value={values ? values.pageLink : ''}
                    onChangeHandler={handleChange('pageLink')}
                    isRequired={true}
                    formikError={errors.pageLink}
                    formikTouch={touched.pageLink}
                    containerClassNames="mt-5"
                  />
                  <InputField
                    title="Description"
                    isDisabled={fieldDisabled}
                    value={values ? values.description : ''}
                    onChangeHandler={handleChange('description')}
                    isRequired={true}
                    formikError={errors.description}
                    formikTouch={touched.description}
                    containerClassNames="mt-5"
                  />
                  <DropDownField
                    title="Key Message"
                    value={values ? values.keyMessage : ''}
                    data={keyMessageList}
                    onChangeHandler={handleChange('keyMessage')}
                    isDisabled={fieldDisabled}
                  />
                  <div className="flex flex-col w-full xl:w-10/12">
                    <div className="flex">
                      <div className="w-7/12">
                        <DropDownField
                          title="Category"
                          value={values.category['categoryId']}
                          onChangeHandler={handleChange(`category.categoryId`)}
                          data={categoryList}
                          containerClassNames="mt-5"
                          isDisabled={fieldDisabled}
                          isRequired={true}
                        />
                        {errors.category && touched.category && (
                          <div className="text-red-500 text-xs mt-1 ml-48 pl-5">
                            {errors.category.categoryId}
                          </div>
                        )}
                      </div>
                      <div className="w-5/12">
                        <DropDownField
                          title="Intensity"
                          value={values.category['intensityId']}
                          onChangeHandler={handleChange(`category.intensityId`)}
                          data={intensityList}
                          titleClassNames="w-20"
                          containerClassNames="mt-5 ml-10"
                          isDisabled={fieldDisabled}
                          isRequired={true}
                        />
                        {errors.category && touched.category && (
                          <div className="text-red-500 text-xs mt-1 ml-30 pl-5">
                            {errors.category.intensityId}
                          </div>
                        )}
                      </div>
                      <button
                        onClick={() => onAddCategories(values, setValues)}
                        className={`focus:outline-none ${fieldDisabled && 'hidden'}`}
                        type="button"
                      >
                        <BsPlusCircleFill className="text-blue-500 mt-5 ml-5" size={25} />
                      </button>
                    </div>
                    <FieldArray name="categories">
                      {() =>
                        values.categories.map((category: any, i: any) => {
                          return (
                            <div key={i} className="flex">
                              <div className="w-7/12">
                                <DropDownField
                                  title="Category"
                                  value={values.categories[i].categoryId}
                                  onChangeHandler={handleChange(`categories.${i}.categoryId`)}
                                  data={categoryList}
                                  containerClassNames="mt-5"
                                  isDisabled={fieldDisabled}
                                />
                              </div>
                              <div className="w-5/12">
                                <DropDownField
                                  title="Intensity"
                                  value={values.categories[i].intensityId}
                                  onChangeHandler={handleChange(`categories.${i}.intensityId`)}
                                  data={intensityList}
                                  titleClassNames="w-20"
                                  containerClassNames="mt-5 ml-10"
                                  isDisabled={fieldDisabled}
                                />
                              </div>
                              <button
                                onClick={() => onRemoveCategories(i, values, setValues)}
                                className={`focus:outline-none ${fieldDisabled && 'hidden'}`}
                                type="button"
                              >
                                <BsDashCircleFill className="text-red-500 mt-5 ml-5" size={25} />
                              </button>
                            </div>
                          );
                        })
                      }
                    </FieldArray>
                  </div>
                  <DropDownField
                    title="Gender report category"
                    value={values ? values.genderReportCategory : []}
                    data={genderReportCategoryList}
                    isDisabled={fieldDisabled}
                    isMultiple={true}
                    onChangeHandler={handleChange('genderReportCategory')}
                  />
                  <div className="flex mt-5 flex-row">
                    <div className="font-medium text-base mr-5 w-48 flex-shrink-0">
                      Target category
                    </div>
                    <div className="w-full">
                      {targetCategoryList.map((category: any, i: any) => {
                        return (
                          <div key={i}>
                            <DropDownField
                              title={category.target}
                              value={
                                values && values.targetCategory[0][category.targetId]
                                  ? values.targetCategory[0][category.targetId]
                                  : []
                              }
                              data={category.options}
                              containerClassNames={i === 0 ? 'mt-0' : 'mt-5'}
                              isMultiple={true}
                              onChangeHandler={handleChange(
                                `targetCategory.0.${category.targetId}`
                              )}
                              isDisabled={fieldDisabled}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <DropDownField
                    title="Source"
                    value={values ? values.source : ''}
                    data={sourceList}
                    isRequired={true}
                    onChangeHandler={handleChange('source')}
                    formikError={errors.source}
                    formikTouch={touched.source}
                    isDisabled={fieldDisabled}
                  />
                  <div>
                    <FilePicker
                      title="Screenshot"
                      isRequired={true}
                      acceptFileType="image/*"
                      onChangeHandler={(e: any) => {
                        if (e.target.files.length !== 0) {
                          setFieldValue('screenshot', e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));
                        }
                      }}
                      fileName={values ? values.screenshot : ''}
                      isDisabled={fieldDisabled}
                      formikError={errors.screenshot}
                      formikTouch={touched.screenshot}
                      containerClassNames="mt-5 mb-5"
                    />
                    {image ? (
                      <img alt="selected" src={image} className="object-contain w-64 ml-48 pl-5" />
                    ) : (
                      incidentData && (
                        <span className="ml-48 pl-5 text-base italic text-gray-500">
                          Cannot preview image :(
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* Perpetrator Details */}
              <div className="border-b-2 border-gray-300 mb-10">
                <span className="font-semibold text-gray-700 text-2xl">Perpetrator Details</span>
                <div className="my-8">
                  <DatePicker
                    title="Date"
                    inputFormat="dd/MM/yyyy"
                    isRequired={true}
                    value={values ? values.date : ''}
                    onChangeHandler={(e: any) => {
                      setFieldValue('date', moment(e).format('YYYY-MM-DD'));
                    }}
                    formikError={errors.date}
                    formikTouch={touched.date}
                    isDisabled={fieldDisabled}
                  />
                  <DropDownField
                    title="Country"
                    value={values ? values.country : ''}
                    data={countryList}
                    onChangeHandler={handleChange('country')}
                    formikError={errors.country}
                    formikTouch={touched.country}
                    isDisabled={fieldDisabled}
                  />
                  {values && values.country === COUNTRY_SRILANKA && (
                    <div className="flex flex-row">
                      <div className="font-medium text-base mr-5 w-48 flex-shrink-0"></div>
                      <div className="w-full">
                        <DropDownField
                          title="Province"
                          value={values ? values.province : ''}
                          data={SRILANKA_PROVINCES}
                          onChangeHandler={e => {
                            setFieldValue('province', e.target.value);
                            onChangeProvince(e.target.value);
                          }}
                          formikError={errors.province}
                          formikTouch={touched.province}
                          isDisabled={fieldDisabled}
                        />
                        {districtList && (
                          <DropDownField
                            title="District"
                            value={values ? values.district : ''}
                            data={districtList}
                            onChangeHandler={handleChange('district')}
                            formikError={errors.district}
                            formikTouch={touched.district}
                            isDisabled={fieldDisabled}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <DropDownField
                    title="Perpetrator gender"
                    value={values ? values.perpetratorGender : ''}
                    data={perpetratorGenderList}
                    isRequired={true}
                    onChangeHandler={handleChange('perpetratorGender')}
                    formikError={errors.perpetratorGender}
                    formikTouch={touched.perpetratorGender}
                    isDisabled={fieldDisabled}
                  />
                  <DropDownField
                    title="Language"
                    value={values ? values.language : ''}
                    data={languageList}
                    isRequired={true}
                    onChangeHandler={handleChange('language')}
                    formikError={errors.language}
                    formikTouch={touched.language}
                    isDisabled={fieldDisabled}
                  />
                  <DropDownField
                    title="Political bias"
                    value={values ? values.politicalBias : ''}
                    data={politicalBiasList}
                    onChangeHandler={handleChange('politicalBias')}
                    isDisabled={fieldDisabled}
                  />
                  <DropDownField
                    title="Type of content"
                    value={values ? values.typeOfContent : ''}
                    data={typeOfContentList}
                    isRequired={true}
                    onChangeHandler={handleChange('typeOfContent')}
                    formikError={errors.typeOfContent}
                    formikTouch={touched.typeOfContent}
                    isDisabled={fieldDisabled}
                  />
                </div>
              </div>
              {/* Engagement Details */}
              <div className="border-b-2 border-gray-300 mb-10">
                <span className="font-semibold text-gray-700 text-2xl">Engagement Details</span>
                <div className="my-8">
                  <DropDownField
                    title="Engagement"
                    value={values ? values.engagement : ''}
                    data={engagementList}
                    isRequired={true}
                    onChangeHandler={handleChange('engagement')}
                    formikError={errors.engagement}
                    formikTouch={touched.engagement}
                    isDisabled={fieldDisabled}
                  />
                  <InputField
                    title="Actual engagement"
                    type="number"
                    value={values ? values.actualEngagement : ''}
                    onChangeHandler={(e: any) => {
                      setFieldValue('actualEngagement', e.target.value);
                      setEngagement(true);
                    }}
                    isDisabled={fieldDisabled}
                    containerClassNames="mt-5"
                  />
                  <DropDownField
                    title="Reported to social media platforms"
                    isDisabled={reportedToSMDisabled}
                    value={values ? values.reportedToSM : ''}
                    data={YES_NO_DROPDOWN}
                    onChangeHandler={handleChange('reportedToSM')}
                  />
                  <DropDownField
                    title="Response"
                    isDisabled={responseDisabled}
                    value={values ? values.engagementResponse : ''}
                    data={engagementResponseList}
                    onChangeHandler={handleChange('engagementResponse')}
                  />
                  <DropDownField
                    title="Time taken for response"
                    isDisabled={timeForResponseDisabled}
                    value={values ? values.timeForResponse : ''}
                    data={timeForResponseList}
                    onChangeHandler={handleChange('timeForResponse')}
                  />
                </div>
              </div>
              {/* Other Details */}
              <div className="border-b-2 border-gray-300 mb-10">
                <div className="my-8">
                  <div className="flex flex-row mt-5 font-medium text-base ">
                    <div className="flex-shrink-0 mr-5 w-48">Reported by</div>
                    <div>{incidentData ? incidentData[1].reportedByName : userData.name}</div>
                  </div>
                  <TextArea
                    title="Notes"
                    isDisabled={notesDisabled}
                    onChangeHandler={handleChange('notes')}
                    value={values ? values.notes : ''}
                    formikError={errors.notes}
                    formikTouch={touched.notes}
                  />
                </div>
              </div>
              {/* Action Buttons */}
              {(showAddAction || showAddApproveAction) && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className={`bg-indigo-500 focus:outline-none ${
                      showAddApproveAction ? 'w-40' : 'w-28'
                    } h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center`}
                    onClick={() => handleIncidents(formikProps, ADD_INCIDENT)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : showAddApproveAction ? (
                      'Add & Approve'
                    ) : (
                      'Add'
                    )}
                  </button>
                </div>
              )}
              {showSaveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}
                  </button>
                </div>
              )}
              {showSaveApproveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-40 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Save & Approve'}
                  </button>
                </div>
              )}
              {showEditCancelApproveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => setAdminEditFields(true)}
                    type="button"
                  >
                    Edit
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps, STATUS_APPROVED)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Approve'}
                  </button>
                </div>
              )}
              {showEditRejectApproveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() => setAdminEditFields(true)}
                    type="button"
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps, STATUS_REJECTED)}
                    type="button"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Reject'}
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps, STATUS_APPROVED)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Approve'}
                  </button>
                </div>
              )}
              {showBackAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/incidents',
                      })
                    }
                    type="button"
                  >
                    Back
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default IncidentForm;

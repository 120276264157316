import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';

import NotFound from './components/screens/404';
import Login from './components/templates/login/LoginForm';
import Dashboard from './components/screens/Dashboard';
import Incidents from './components/screens/Incidents';
import IncidentForm from './components/screens/Incidents/Form';
import Reports from './components/screens/Reports';
import AddReport from './components/screens/Reports/AddReport';
import Profile from './components/screens/Profile';
import AddUserRole from './components/screens/Users/AddUserRole';
import Users from './components/screens/Users';
import KeyObservations from './components/screens/KeyObservations';
import AddObservation from './components/screens/KeyObservations/AddObservation';
import AddBulkReport from './components/screens/Incidents/AddBulkReport';

import { USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION } from './Constants';

function App() {
  return (
    <div>
      <Router>
        <Route exact path="/login" component={Login} />
        <Switch>
          <ProtectedRoute
            exact
            path="/"
            component={Dashboard}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
          <ProtectedRoute
            exact
            path="/incidents"
            component={Incidents}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/incidents/add"
            component={IncidentForm}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/incidents/:incidentId"
            component={IncidentForm}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="/reports"
            component={Reports}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
          <ProtectedRoute
            exact
            path="/reports/add"
            component={AddReport}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/profile"
            component={Profile}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
          <ProtectedRoute exact path="/users" component={Users} roles={[USER_ROLE_ADMIN]} />
          <ProtectedRoute
            exact
            path="/users/add"
            component={AddUserRole}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/keyObservations"
            component={KeyObservations}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/keyObservations/add"
            component={AddObservation}
            roles={[USER_ROLE_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/incident/addBulkReport"
            component={AddBulkReport}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR]}
          />
          <ProtectedRoute
            exact
            path="*"
            component={NotFound}
            roles={[USER_ROLE_ADMIN, USER_ROLE_MONITOR, USER_ROLE_ORGANIZATION]}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

export const sortArrayAscending = (arr: []) => {
  const sortedList = arr.sort((a: any, b: any) => {
    const item1 = a.name.toLowerCase(),
      item2 = b.name.toLowerCase();

    if (item1 < item2) {
      return -1;
    }
    if (item1 > item2) {
      return 1;
    }
    return 0;
  });

  return sortedList;
};

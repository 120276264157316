import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div
      style={{ backgroundColor: '#222221' }}
      className="w-full h-14 flex justify-center items-center absolute bottom-0"
    >
      <div className="text-base font-medium text-white px-5">Designed & Developed by</div>
      <Link to={{ pathname: 'https://rootcodelabs.com/' }} target="_blank">
        <img
          className="w-28 px-2 py-1 object-contain bg-white"
          src="/rootcode.png"
          alt="rootcode logo"
        />
      </Link>
    </div>
  );
}
